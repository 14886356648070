import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  tab: {
    border: `1px solid ${theme.palette.background.default}`,
    color: theme.palette.background.default,
    background: theme.palette.primary.main,
    width: "100%",
    height: "3rem",
    "&.MuiButton-root:hover": {
      color: theme.palette.primary.main,
      background: theme.palette.background.default,
    },
  },
  containerSubcatChips: {
    display: "flex",
    justifyContent: "center",
  },
}));
export default function TabPanel({ items }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box>
      <Grid container className={classes.containerSubcatChips}>
        {items?.map((item, idx) => {
          return (
            <Grid key={idx} item xs={6} md={12 / items.length}>
              <Button
                className={classes.tab}
                onClick={() =>
                  history.push(`/products?category=${item?.seoName}`)
                }
              >
                {item?.name}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
