import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiInputBase-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontSize: "18px",
      paddingLeft: theme.spacing(2),
    },
    "& .MuiFilledInput-root": {
      background: theme.palette.background.default,
    },
    "& .MuiFilledInput-input": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      width: 300,
      borderRadius: 4,
      paddingLeft: theme.spacing(3.5),
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        width: 200,
      },
    },
    "& .MuiOutlinedInput-root": {
      background: theme.palette.background.default,
    },
    "& .MuiOutlinedInput-input": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      width: 300,
      borderRadius: 4,
      paddingLeft: theme.spacing(3.5),
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        width: 200,
      },
    },
    "& .MuiButton-label": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.4),
      paddingBottom: theme.spacing(0.4),
      fontSize: "14px",
    },
  },
}));
const CustomForm = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <form className={classes.form}>{children}</form>
    </Box>
  );
};

export default CustomForm;
