import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import { Link as RouterLink } from "react-router-dom";
import { ImageFit } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import ResizedImage from "../ResizedImage";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles((theme) => ({
  containerHomeCarouselImage: {
    margin: "32px 0px",
  },
  boxHomeCarouselOuter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //background: colors.gradient0,
    width: "100%",
    height: Math.round(window.innerHeight * 0.95),
  },
}));



const HomeCarousel = ({ data }) => {
  const classes = useStyles();

  const CustomRouterLink = ({ item, children }) => {
    return (
      <>
        {
          item?.relatedUrl ? (
            <a href={item?.relatedUrl} target="_blank" rel="noreferrer">{children}</a>
          ) : (
            <RouterLink
              to={
                item.relatedProduct
                  ? `/product/${item.relatedProduct.seoName}`
                  : item.relatedCategory
                    ? `/products?category=${item.relatedCategory.seoName}`
                    : `/`
              }
            >
              {children}
            </RouterLink>
          )
        }
      </>
    )
  }


  return (
    <Box
      className={classes.boxHomeCarouselOuter}
      id="home-carousel"
      style={{
        background: colors.gradient0,
      }}
    >
      <Carousel
        showThumbs={false}
        showStatus={false}
        dynamicHeight={false}
        showIndicators={false}
        stopOnHover
        swipeable
        infiniteLoop
        autoPlay
        width={Math.round(window.innerWidth * 0.95)}
      >
        {data?.map((item, idx) => {
          return (
            <CustomRouterLink item={item} key={idx}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={12}>
                  <Typography color="textPrimary" variant="h4">
                    {item.subtitle}
                  </Typography>
                  <Typography color="textPrimary" variant="h3">
                    {item.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.containerHomeCarouselImage}
                >
                  <ResizedImage
                    item={item?.images[0]?.img}
                    alternateText={item.title}
                    multiples={[0.85, 0.75, 0.35, 0.5]}
                    fit={ImageFit.INSIDE}
                  />
                </Grid>
              </Grid>
            </CustomRouterLink>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default HomeCarousel;
