import { useState, useEffect, useCallback } from "react";
import CommonLayout from "../../layouts/CommonLayout";
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";
import {
  GET_ORDER_INVOICE,
  GET_ACTIVE_CART,
  GET_CUSTOMER_PROFILE,
} from "../../graphql/Queries";
import { ADD_ORDER, APPLY_COUPON, INIT_PAYMENT } from "../../graphql/Mutations";
import InvoiceProducts from "./Products";
import InvoiceAddress from "./Addresses";
import AddressCard from "../../components/AddressCard";
import CustomSnackbar from "../../components/CustomSnackbar";
import LottieAnimation from "../../helpers/lottie";
import OrderPlacedAnimation from "../../animations/order-placed.json";
import { useHistory } from "react-router";
import { handleAsyncCalls } from "../../helpers/handleAsyncApiCalls";
import CustomStepper from "../../components/CustomStepper";
import AddReferralCode from "../../components/AddReferralCode";
import CustomDialog from "../../components/CustomDialog";
import CustomForm from "../../components/CustomForm";
import { beginCheckoutPage, purchasePage } from "../../helpers/googleAnalytics";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  containerMyCart: {
    display: "flex",
    flexDirection: "column",
  },
  textMyCartTitle: {
    letterSpacing: "0.02em",
    margin: "2rem 0rem 0rem 0rem",
  },
  myCartItemWrapper: {
    padding: "16px",
  },
  containerMyCartItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleContainerMyCart: {
    display: "flex",
    justifyContent: "center",
  },
  containerInvoiceTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0px 0px 16px 16px",
  },

  containerStepperInvoice: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const CheckoutView = () => {
  const classes = useStyles();
  const history = useHistory();

  const client = useApolloClient();
  const [activeStep, setActiveStep] = useState(0);
  const [orderId, setOrderId] = useState(-1);
  const [selectedAddress, setSelectedAddress] = useState();
  const [orderPlacementSuccess, setOrderPlacementSuccess] = useState(false);

  const [addressCreateSuccess, setAddressCreateSuccess] = useState(0);
  const [addressCreateSuccessSnackOpen, setAddressCreateSuccessSnackOpen] =
    useState(false);

  const [addressNotSelectedSnackOpen, setAdddressNotSelectedSnackOpen] =
    useState(false);

  const [referralCode, setReferralCode] = useState();

  const [openCouponDialog, setOpenCouponDialog] = useState(false);
  const [couponName, setCouponName] = useState("");
  const [showCouponFeedback, setShowCouponFeedback] = useState(false);
  const [couponAdditionSuccess, setCouponAdditionSuccess] = useState(0);

  const [termsCheckboxSelection, setTermsCheckboxSelection] = useState(false);

  const { data: invoiceData, refetch } = useQuery(GET_ORDER_INVOICE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("On completed of order invoice", data);
      beginCheckoutPage(JSON.parse(localStorage.getItem("inova-customer")).name)
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    refetch();
  }, [couponAdditionSuccess, refetch]);

  const [applyCoupon, { data: couponFeedback }] = useMutation(APPLY_COUPON, {
    variables: {
      couponName: couponName,
    },
    onCompleted: (data) => {
      console.log("On completed of apply coupon", data);
      setShowCouponFeedback(true);
      setCouponAdditionSuccess((prev) => prev + 1);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  const [addOrder] = useMutation(ADD_ORDER, {
    variables: {
      addressId: selectedAddress?.id,
      referralCode: referralCode,
    },
    onCompleted: (data) => {
      console.log("On completed of add order", data);
      purchasePage(data.addOrder.id, JSON.parse(localStorage.getItem("inova-customer")).name)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setOrderPlacementSuccess(true);
      setOrderId(parseInt(data?.addOrder?.order?.id));
    },
    onError: (error) => {
      console.log("Error in add order", error);
    },
  });

  const [initPayment] = useMutation(INIT_PAYMENT, {
    variables: {
      orderId: orderId,
    },
    onCompleted: (data) => {
      console.log("On completed of pay now", data);
      let gatewayUrl =
        JSON.parse(data?.initPayment?.response)?.GatewayPageURL || "";
      if (gatewayUrl !== "") window.open(gatewayUrl, "_blank");
    },
    onError: (error) => {
      console.log("Error in pay now", error);
    },
  });

  const [getActiveCart] = useLazyQuery(GET_ACTIVE_CART, {
    onCompleted: (data) => {
      console.log("On completed of active cart after order placement", data);
      if (data) {
        localStorage.setItem(
          "inova-cart",
          JSON.stringify(data?.activeCart?.cartproductsOfThisCart)
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getActiveCart();
  }, [orderPlacementSuccess, getActiveCart]);

  const getCustomerProfile = useCallback(() => {
    handleAsyncCalls(client, GET_CUSTOMER_PROFILE, (data) => {
      console.log("ASYNC APOLLO CUSTOMER PROFILE", data);
      if (data) {
        localStorage.setItem(
          "inova-customer",
          JSON.stringify(data?.data?.customer)
        );
        setAddressCreateSuccessSnackOpen(true);
      }
    });
  }, [client]);
  //console.log(newAddress);

  useEffect(() => {
    if (addressCreateSuccess !== 0) getCustomerProfile();
  }, [addressCreateSuccess, getCustomerProfile]);

  const handleNext = () => {
    if (activeStep === 1 && !selectedAddress) {
      setAdddressNotSelectedSnackOpen(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePlaceOrder = () => {
    //console.log("Place order");
    if (!selectedAddress) {
      setAdddressNotSelectedSnackOpen(true);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      return;
    }
    if (selectedAddress && selectedAddress.id) addOrder();
  };

  return (
    <>
      <CommonLayout>
        <Helmet>
          <title>Checkout</title>

          <meta name="title" content="Checkout" />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Checkout" />
          <meta property="og:image" content={process.env.REACT_APP_BRAND_LOGO} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={window.location.href} />
          <meta property="twitter:title" content="Checkout" />
          <meta property="twitter:image" content={process.env.REACT_APP_BRAND_LOGO} />
        </Helmet>
        <Grid container className={classes.containerMyCart}>
          <Grid item className={classes.titleContainerMyCart}>
            <Typography
              color="textSecondary"
              variant="h3"
              className={classes.textMyCartTitle}
            >
              Checkout
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            className={classes.containerStepperInvoice}
          >
            <CustomStepper activeStep={activeStep} />
          </Grid>
          <Grid item xs={12} md={12}>
            {activeStep === 0 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.containerInvoiceTitle}
                >
                  <Typography variant="h4">Order Summary</Typography>

                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      position: "absolute",
                      right: "16px",
                    }}
                    onClick={() => setOpenCouponDialog(true)}
                  >
                    Apply Coupon
                  </Button>
                </Grid>
                <InvoiceProducts invoiceData={invoiceData} />
              </>
            ) : (
              <></>
            )}
            {activeStep === 1 ? (
              <Grid container>
                <Grid item xs={12} md={4}>
                  <AddReferralCode setReferralCode={setReferralCode} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <InvoiceAddress
                    handleAddressSelection={setSelectedAddress}
                    setAddressCreateSuccess={setAddressCreateSuccess}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {activeStep === 2 ? (
              <>
                <AddressCard address={selectedAddress} />
                <InvoiceProducts invoiceData={invoiceData} />
                <Grid container justifyContent="center">
                  <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsCheckboxSelection}
                      onChange={() => setTermsCheckboxSelection(!termsCheckboxSelection)}
                      name="terms"
                      color="primary"
                    />
                  }
                  label="I have read the terms & conditions, privacy policy and refund & return policy and agree with them."
                />
                </Grid>
                
              </>
            ) : (
              <></>
            )}
            {activeStep === 3 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  justifyContent="center"
                //style={{ marginTop: "16px" }}
                >
                  <Typography variant="h5" color="primary" align="center">
                    Your order has been placed successfully. One of our
                    representatives will contact you very soon. Thank you.
                  </Typography>
                </Grid>
                <LottieAnimation
                  lotti={OrderPlacedAnimation}
                  height={200}
                  width={400}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            justifyContent="center"
            spacing={3}
            style={{
              paddingTop: "16px",
            }}
          >
            {activeStep === 3 ? (
              <>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    onClick={() => history.push("/")}
                  >
                    Back To Home
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    onClick={() => history.push("/profile")}
                  >
                    My Orders
                  </Button>
                </Grid>
                {localStorage.getItem("inova-test-url") &&
                  localStorage.getItem("inova-test-url") === "1" ? (
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      onClick={() => initPayment()}
                    >
                      Pay Now
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  {activeStep !== 2 ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      disabled={!termsCheckboxSelection}
                      onClick={handlePlaceOrder}
                    >
                      Place Order
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={12} container justifyContent="center" style={{
            padding: "32px 8px 0px 8px",
          }}>
            <Typography variant="body1" color="textSecondary">
              INOVA Furniture Ltd. will ensure delivery within 5-10 business days of full payment completion.
            </Typography>
          </Grid>
        </Grid>
      </CommonLayout>
      {showCouponFeedback ? (
        <CustomSnackbar
          open={showCouponFeedback}
          setOpen={setShowCouponFeedback}
          severity={
            couponFeedback?.applyCoupon?.isSuccess ? "success" : "error"
          }
          message={couponFeedback?.applyCoupon?.message}
        />
      ) : (
        <></>
      )}
      {addressNotSelectedSnackOpen ? (
        <CustomSnackbar
          open={addressNotSelectedSnackOpen}
          setOpen={setAdddressNotSelectedSnackOpen}
          severity="warning"
          message="Please select an address first"
        />
      ) : (
        <></>
      )}
      {addressCreateSuccess ? (
        <CustomSnackbar
          open={addressCreateSuccessSnackOpen}
          setOpen={setAddressCreateSuccessSnackOpen}
          severity="success"
          message="Address Creation Successful!"
        />
      ) : (
        <></>
      )}
      {openCouponDialog ? (
        <CustomDialog
          open={openCouponDialog}
          handlePositiveButtonAction={() => {
            applyCoupon();
            setOpenCouponDialog(false);
          }}
          positiveButtonText="Add Coupon"
          handleNegativeButtonAction={() => setOpenCouponDialog(false)}
          negativeButtonText="Close"
        >
          <CustomForm>
            <TextField
              variant="filled"
              type="text"
              InputLabelProps={{ color: "primary" }}
              id="coupon"
              label="Coupon Code"
              onChange={(e) => setCouponName(e.target.value)}
            />
          </CustomForm>
        </CustomDialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default CheckoutView;
