import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import CommonLayout from "../../layouts/CommonLayout";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useState, useCallback, useEffect } from "react";
import constants from "../../data/constants.json";
import { useHistory } from "react-router-dom";
import { useMutation, useApolloClient } from "@apollo/client";
import useQueryParams from "../../hooks/useQueryParams";
import { LOGINV2 } from "../../graphql/Mutations";
import { loginPageView, setUserProps } from "../../helpers/googleAnalytics";
import { handleAsyncCalls } from "../../helpers/handleAsyncApiCalls";
import { GET_ACTIVE_CART, GET_CUSTOMER_PROFILE } from "../../graphql/Queries";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "64px 0px 0px 0px",
  },
  textSignIn: {
    fontWeight: "bold",
    marginBottom: "24px"
  },
  signupText: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    //fontSize: "20px",
  },
  signup: {
    fontSize: "20px",
    marginLeft: theme.spacing(1),
    textDecoration: "underline",
  },
}));
const LoginView = () => {
  const classes = useStyles();
  const { redirectUrl } = useQueryParams();
  const history = useHistory();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [token, setToken] = useState();
  const [authMethod, setAuthMethod] = useState();
  const [username, setUsername] = useState();
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const client = useApolloClient();

  const getActiveCart = useCallback(() => {
    handleAsyncCalls(client, GET_ACTIVE_CART, (data) => {
      console.log("ASYNC APOLLO ACTIVE CART", data);
      if (data) {
        localStorage.setItem(
          "inova-cart",
          JSON.stringify(data?.data?.activeCart?.cartproductsOfThisCart)
        );
      }
    });
  }, [client]);

  const getCustomerProfile = useCallback(() => {
    handleAsyncCalls(client, GET_CUSTOMER_PROFILE, (data) => {
      console.log("ASYNC APOLLO CUSTOMER PROFILE", data);
      if (data) {
        localStorage.setItem(
          "inova-customer",
          JSON.stringify(data?.data?.customer)
        );
      }
    });
  }, [client]);
  const [login] = useMutation(LOGINV2, {
    variables: {
      authMethod: authMethod,
      firebaseIdtoken: token,
      username: username,
      phone: phoneNumber,
      email: email
    },
    onCompleted: (data) => {
      console.log("LOGIN ", data);
      setUserProps(username);
      loginPageView(username, true);
      if (data) {
        localStorage.setItem("inova-token", data?.signInV2?.token);
        //localStorage.setItem("inova-customer", JSON.stringify(data.signIn.payload));
        setLoginSuccess(true);
      }
    },
    onError: (error) => {
      loginPageView(username, false);
      console.log(error);
      setShowErrorAlert(true);
    },
  });
  useEffect(() => {
    if (loginSuccess) {
      getCustomerProfile();
      getActiveCart();

      redirectUrl ? history.push(redirectUrl) : history.push("/");
    }
  }, [history, loginSuccess, redirectUrl, getActiveCart, getCustomerProfile]);

  useEffect(() => {
    if (username && authMethod && token) {
      if (phoneNumber || email)
        login();
    }
  }, [username, authMethod, token, phoneNumber, email, login])


  var uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: "BD",
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        firebase
          .auth()
          .currentUser.getIdToken(false)
          .then(function (idToken) {
            // Send token to your signup mutation
            //console.log(idToken, "found");
            //console.log(authResult.additionalUserInfo.providerId);
            setToken(idToken);
            setAuthMethod(authResult.additionalUserInfo.providerId);
            if (authResult.additionalUserInfo.providerId === constants.authMethodPhone) {
              setUsername(authResult.user.phoneNumber);
              setPhoneNumber(authResult.user.phoneNumber);
            } else if (authResult.additionalUserInfo.providerId === constants.authMethodGoogle) {
              setUsername(authResult.user.email);
              setEmail(authResult.user.email);
            } else {
              console.log("Invalid Auth Method");
            }
          })
          .catch(function (error) {
            // Handle error
            console.log("callback:", error);
          });
      },
    },
  };



  return (
    <>
      <CommonLayout>
        <Container className={classes.root}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h3"
              color="secondary"
              className={classes.textSignIn}
            >
              Already a Member?
            </Typography>
            <Typography
              variant="h3"
              color="secondary"
              className={classes.textSignIn}
            >
              Sign In
            </Typography>
            <StyledFirebaseAuth
              className={classes.boxFirebaseSignup}
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
            <div className={classes.signupText}>
              <Typography variant="h4" style={{ marginRight: "16px" }}>Not a Member?</Typography>
              <Button variant="contained" color="primary" onClick={() => history.push('/register')}>
                Sign Up
              </Button>
            </div>
          </Grid>
        </Container>
      </CommonLayout>
      {showErrorAlert ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Sign In Failed -- <strong>Invalid Credentials</strong>
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoginView;
