import {
  Grid,
  Box,
  Typography,
  //makeStyles
} from "@material-ui/core";
import { hexToRGB } from "../../helpers/hexToRGB";
import { ImageFit } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import ResizedImage from "../ResizedImage";
//const useStyles = makeStyles((theme) => ({}));
const ItemCard = ({ imageLink, name, meta, price }) => {
  //const classes = useStyles();
  return (
    <Box style={{ padding: "16px" }}>
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "6px",
        }}
      >
        <Grid item xs={12} md={12}>
          <Box>
            <ResizedImage
              item={imageLink}
              alternateText={name}
              multiples={[0.7, 0.175, 0.15, 0.2]}
              fit={ImageFit.CONTAIN}
              bg={hexToRGB(colors.white1)}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography gutterBottom color="textSecondary" variant="h5" align="center">
            {name}
          </Typography>
          {price ? (
            <>
              {price > 1 ? (
                <Typography gutterBottom color="textSecondary" variant="h5">
                  ৳ {parseInt(price).toLocaleString()}
                </Typography>
              ) : (
                <Typography gutterBottom color="textSecondary" variant="h5">
                  Contact For Price
                </Typography>
              )}
            </>
          ) : (
            <></>
          )}
          {meta ? (
            <Typography color="textSecondary" variant="h5">
              {meta}
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemCard;
