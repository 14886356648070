import { Box, Slider, makeStyles } from "@material-ui/core";
import constants from "../../data/constants.json";

// const formatLabel = (value) => {
//   return <div>{value * constants.priceMultiple}</div>;
// };

const useStyles = makeStyles((theme) => ({
  slider: {
    color: theme.palette.info.main,

    "& .MuiSlider-track": {
      height: "10px",
      borderRadius: "4px",
    },

    "& .MuiSlider-thumb": {
      width: "20px",
      height: "20px",
      background: "#fff",
      border: "2px solid #EF4A23",
      boxShadow:
        "0 0 0 4px #fff inset, 0 0 0 9px #EF4A23 inset, 0 0 0 2px #fff",
      cursor: "pointer",
    },

    "& .MuiSlider-rail": {
      height: "8px",
      borderRadius: "4px",
    },
  },
}));

const RangeSlider = ({ value, handleChange }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Slider
        className={classes.slider}
        getAriaLabel={() => "Price range"}
        min={constants.priceLow}
        max={constants.priceHigh}
        step={constants.priceStep}
        marks
        value={value}
        onChange={handleChange}
        //valueLabelDisplay="auto"
        //valueLabelFormat={(value) => formatLabel(value)}
      />
    </Box>
  );
};

export default RangeSlider;
