import { useState, useEffect, useCallback } from "react";
import CommonLayout from "../../layouts/CommonLayout";
import {
  Grid,
  Typography,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Trash2 } from "react-feather";
import ItemCard from "../../components/ItemCard";
import { Link as RouterLink } from "react-router-dom";
import { isLoggedIn } from "../../helpers/loginStatus";
import { useMutation, useApolloClient } from "@apollo/client";
import { REMOVE_FROM_WISHLIST } from "../../graphql/Mutations";
import { GET_CUSTOMER_PROFILE } from "../../graphql/Queries";
import { handleAsyncCalls } from "../../helpers/handleAsyncApiCalls";
import CustomSnackbar from "../../components/CustomSnackbar";
import { colors } from "@material-ui/core";
import { addToWishlistPage } from "../../helpers/googleAnalytics";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  containerMyWishlist: {
    display: "flex",
    flexDirection: "column",
  },
  textMyWishlistTitle: {
    letterSpacing: "0.02em",
    margin: "2rem 0rem",
  },
  myWishlistItemWrapper: {
    padding: "16px",
    position: "relative",
  },
  containerMyWishlistItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  titlecontainerMyWishlist: {
    display: "flex",
    justifyContent: "center",
  },
}));

const WishlistView = () => {
  const classes = useStyles();
  const client = useApolloClient();

  const [wishlistItemRemoveEvent, setWishlistItemRemoveEvent] = useState(-1);
  const [wishlistRemovalSuccess, setWishlistRemovalSuccess] = useState(0);
  const [wishlistSuccessSnackOpen, setWishlistSuccessSnackOpen] =
    useState(false);

  const [removeFromWishlist] = useMutation(REMOVE_FROM_WISHLIST, {
    variables: {
      productId: wishlistItemRemoveEvent,
    },
    onCompleted: (data) => {
      console.log("Removed from wishlist", data);
      addToWishlistPage(JSON.parse(localStorage.getItem("inova-customer")).name, "removed", wishlistItemRemoveEvent);
      setWishlistRemovalSuccess((prev) => prev + 1);
    },
    onError: (error) => {
      console.log("Error in removing from wishlist", error);
    },
  });

  const getCustomerProfile = useCallback(() => {
    handleAsyncCalls(client, GET_CUSTOMER_PROFILE, (data) => {
      console.log("ASYNC APOLLO CUSTOMER PROFILE", data);
      if (data) {
        localStorage.setItem(
          "inova-customer",
          JSON.stringify(data?.data?.customer)
        );
        setWishlistSuccessSnackOpen(true);
      }
    });
  }, [client]);

  useEffect(() => {
    if (wishlistItemRemoveEvent !== -1) {
      setWishlistSuccessSnackOpen(false);
      removeFromWishlist();
    }
  }, [wishlistItemRemoveEvent, removeFromWishlist]);

  useEffect(() => {
    if (wishlistRemovalSuccess !== 0) getCustomerProfile();
  }, [wishlistRemovalSuccess, getCustomerProfile]);

  return (
    <>
      <Helmet>
        <title>WishList</title>

        {/* <meta name="description" content={ "DESCRIPTION" } /> */}
        <meta name="title" content="WishList" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="WishList" />
        {/* <meta property="og:description" content={ "DESCRIPTION" } /> */}
        <meta property="og:image" content={process.env.REACT_APP_BRAND_LOGO} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content="WishList" />
        {/* <meta property="twitter:description" content={ "DESCRIPTION" } /> */}
        <meta property="twitter:image" content={process.env.REACT_APP_BRAND_LOGO} />
      </Helmet>
      <CommonLayout>
        <Box>
          <Grid container className={classes.containerMyWishlist}>
            <Grid item className={classes.titlecontainerMyWishlist}>
              <Typography
                color="textSecondary"
                variant="h3"
                className={classes.textMyWishlistTitle}
              >
                Wishlist
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                {isLoggedIn() && localStorage.getItem("inova-customer") ? (
                  <>
                    {JSON.parse(localStorage.getItem("inova-customer"))
                      ?.wishlistproductsOfThisCustomer?.length === 0 ? (
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Typography variant="h4" color="primary">
                          No Item in Wishlist
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {JSON.parse(
                      localStorage.getItem("inova-customer")
                    )?.wishlistproductsOfThisCustomer?.map((item, idx) => {
                      return (
                        <Grid
                          item
                          key={idx}
                          xs={12}
                          md={4}
                          className={classes.myWishlistItemWrapper}
                        >
                          <RouterLink to={`/product/${item?.product?.seoName}`}>
                            <ItemCard
                              imageLink={item?.product?.images[0]?.img}
                              name={item?.product?.name}
                              price={item?.product?.unitPrice}
                            />
                          </RouterLink>
                          <IconButton
                            style={{
                              color: colors.grey1,
                              position: "absolute",
                              top: 20,
                              right: 20,
                            }}
                            onClick={() => {
                              setWishlistItemRemoveEvent(
                                parseInt(item?.product?.id)
                              );
                            }}
                          >
                            <Trash2 />
                          </IconButton>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CommonLayout>
      {wishlistRemovalSuccess !== 0 ? (
        <CustomSnackbar
          open={wishlistSuccessSnackOpen}
          setOpen={setWishlistSuccessSnackOpen}
          severity="success"
          message="Item removed from wishlist!"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default WishlistView;
