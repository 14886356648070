import { useParams, useHistory } from "react-router";
import CommonLayout from "../../layouts/CommonLayout";
import { Grid, Typography, Button } from "@material-ui/core";

const PaymentStatusView = () => {
  const { status } = useParams();
  const history = useHistory();

  return (
    <CommonLayout>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          md={12}
          container
          justifyContent="center"
          style={{ margin: "64px" }}
        >
          {status === "success" ? (
            <Typography variant="h4" color="primary" align="center">
              Great! Your payment is successful.
            </Typography>
          ) : (
            <></>
          )}
          {status === "fail" ? (
            <Typography variant="h4" color="primary" align="center">
              Payment failed. Please try again.
            </Typography>
          ) : (
            <></>
          )}
          {status === "cancel" ? (
            <Typography variant="h4" color="primary" align="center">
              Your payment got cancelled. Please try again.
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={() => history.push("/")}
          >
            Back To Home
          </Button>
        </Grid>
      </Grid>
    </CommonLayout>
  );
};

export default PaymentStatusView;
