import { Typography, Grid, makeStyles } from "@material-ui/core";
import { hexToRGB } from "../../helpers/hexToRGB";
import { ImageFit } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import ResizedImage from "../../components/ResizedImage";
const useStyles = makeStyles((theme) => ({
  boxWrapperInvoice: {
    padding: "16px",
    //margin: "8px",
  },
  gridItemInvoiceItem: {
    marginBottom: "8px",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "8px",
      padding: "8px",
    },
  },
  textContainerNameInvItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainerSubtotalInv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textContainerOtherInvItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainerPriceInvItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textInvItem: {
    wordBreak: "break-word",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
  },
  textDiscountInvItem: {
    color: colors.green0,
    // fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
  },
}));

const InvoiceProducts = ({ invoiceData }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.boxWrapperInvoice}>
      {invoiceData?.orderInvoice?.orderedProducts?.map((item, idx) => {
        return (
          <Grid
            item
            container
            key={idx}
            xs={12}
            md={12}
            className={classes.gridItemInvoiceItem}
          >
            <Grid
              item
              xs={2}
              md={2}
              className={classes.textContainerNameInvItem}
            >
              <ResizedImage
                item={item?.product?.images[0]?.img}
                alternateText={item?.product?.name}
                multiples={[0.12, 0.15, 0.1, 0.12]}
                fit={ImageFit.CONTAIN}
                bg={hexToRGB(colors.white1)}
              />
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
              className={classes.textContainerNameInvItem}
            >
              <Typography variant="h5" className={classes.textInvItem}>
                {item?.product?.name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              className={classes.textContainerOtherInvItem}
            >
              <Typography variant="h5" className={classes.textInvItem}>
                ৳ {parseInt(item?.product?.unitPrice).toLocaleString()}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              className={classes.textContainerOtherInvItem}
            >
              <Typography variant="h5" className={classes.textInvItem}>
                X {item?.qty}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
              className={classes.textContainerPriceInvItem}
            >
              <Typography variant="h4" className={classes.textInvItem}>
                ৳ {parseInt(item?.totalAmountWithDiscount).toLocaleString()}
              </Typography>

              {item?.totalDiscountAmount !== 0 ? (
                <>
                  <Typography
                    variant="h5"
                    className={classes.textDiscountInvItem}
                  >
                    ৳ {parseInt(item?.totalDiscountAmount).toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.textDiscountInvItem}
                  >
                    discount
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Grid
        item
        container
        xs={12}
        md={12}
        className={classes.gridItemInvoiceItem}
      >
        <Grid item xs={9} md={9} className={classes.textContainerSubtotalInv}>
          <Typography variant="h5" className={classes.textInvItem}>
            Excluding Vat
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} className={classes.textContainerOtherInvItem}>
          <Typography variant="h5" className={classes.textInvItem}>
            ৳{" "}
            {parseInt(
              invoiceData?.orderInvoice?.totalAmountWithoutVat
            ).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={12}
        className={classes.gridItemInvoiceItem}
      >
        <Grid item xs={9} md={9} className={classes.textContainerSubtotalInv}>
          <Typography variant="h5" className={classes.textInvItem}>
            {`Total Vat (${invoiceData?.orderInvoice?.vatPercentage * 100}%)`}
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} className={classes.textContainerOtherInvItem}>
          <Typography variant="h5" className={classes.textInvItem}>
            ৳ {parseInt(invoiceData?.orderInvoice?.totalVat).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
      {invoiceData?.orderInvoice?.appliedCouponName &&
      invoiceData?.orderInvoice?.appliedCouponName !== "" ? (
        <Grid
          item
          container
          xs={12}
          md={12}
          className={classes.gridItemInvoiceItem}
        >
          <Grid item xs={9} md={9} className={classes.textContainerSubtotalInv}>
            <Typography variant="h5" className={classes.textInvItem}>
              {`Coupon Discount Amount (${invoiceData?.orderInvoice?.appliedCouponName})`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            className={classes.textContainerOtherInvItem}
          >
            <Typography variant="h5" className={classes.textInvItem}>
              - ৳ {parseInt(invoiceData?.orderInvoice?.couponDiscountAmount).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        item
        container
        xs={12}
        md={12}
        className={classes.gridItemInvoiceItem}
      >
        <Grid item xs={9} md={9} className={classes.textContainerSubtotalInv}>
          <Typography variant="h4" className={classes.textInvItem}>
            Net Payable
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} className={classes.textContainerOtherInvItem}>
          <Typography variant="h4" className={classes.textInvItem}>
            ৳{" "}
            {parseInt(invoiceData?.orderInvoice?.totalAmount).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvoiceProducts;
