import { Button, makeStyles } from "@material-ui/core";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import { resizeImage } from "../../helpers/resizeImage";

const useStyles = makeStyles((theme) => ({
  containerDesigner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "4px 11px 58px rgba(0, 0, 0, 0.07);",
    borderRadius: "12px",
    height: "533px",
    width: "80%",
    padding: "15px 20px",
    flex: "none",
    order: "2",
    flexGrow: "0",
    margin: "0px 30px",
    background: "#FFFFFF",
  },
}));
const PackageCard = ({ data }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.containerDesigner} container>
      <Grid item xs={12} md={12} style={{ padding: "0px 0px 8px 0px" }}>
        <Typography
          color="textSecondary"
          variant="h5"
          align="inherit"
          style={{
            color: "rgba(48, 48, 48, 1)",
            lineHeight: "1rem",
            fontWeight: "600",
            margin: "4px 0px",
            paddingBottom: "8px",
          }}
        >
          {data.title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          padding: "0px 0px 8px 0px",
          minHeight: "385px",
        }}
      >
        {data?.service.map((item, idx) => {
          return (
            <Grid
              container
              item
              xs={12}
              md={12}
              key={idx}
              style={{
                padding: "0px 0px 8px 0px",
              }}
            >
              <Grid item xs={2} md={2}>
                <Box>
                  <img
                    src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
                      item?.imageIcon,
                      {}
                    )}`}
                    alt="icon"
                    style={{
                      height: "25px",
                      width: "25px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={10} md={10} style={{ padding: "0px 0px 8px 0px" }}>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  align="inherit"
                  style={{
                    color: "#696866",
                    fontSize: ".75rem",
                    lineHeight: "1rem",
                    fontWeight: "500",
                    padding: "0px 0px 8px 8px",
                  }}
                >
                  {item.title}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid container item xs={12} md={12}>
        <Grid item xs={6} md={6} style={{ padding: "0px 0px 8px 0px" }}>
          <Typography
            color="textSecondary"
            variant="h5"
            align="left"
            style={{
              color: "rgba(48, 48, 48, 1)",
              lineHeight: "1rem",
              fontWeight: "600",
              margin: "4px 0px",
              paddingTop: "8px",
            }}
          >
            Price
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography
            color="textSecondary"
            variant="h5"
            align="right"
            style={{
              color: "rgba(48, 48, 48, 1)",
              fontWeight: "600",
              margin: "0px 10px",
              paddingTop: "8px",
            }}
          >
            {data.price}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} style={{ padding: "0px 0px 8px 0px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: "rgba(239, 65, 35, 1)",
            color: "rgba(255, 255, 255, 1)",
            fontWeight: "600",
            padding: "8px 16px 8px 16px",
            width: "100%",
            borderRadius: "52px",
          }}
          onClick={() => {
            window.open(`mailto:tech.inovabd@gmail.com`, "_blank");
          }}
        >
          Contact Us
        </Button>
      </Grid>
    </Grid>
  );
};
export default PackageCard;
