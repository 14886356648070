import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { resizeImage } from "../../helpers/resizeImage";

const useStyles = makeStyles((theme) => ({
  containerDesigner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgba(240, 240, 240, 1) 0px 4px 8px 0px",
    stroke: "rgba(218, 218, 218, 1)",
    border: "1px solid #DADADA",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    "&.MuiGrid-spacing-xs-3": {
      width: "inherit",
      margin: "inherit",
    },
    height: "150px",
    flex: "none",
    order: "2",
    [theme.breakpoints.down("sm")]: {
      order: "1",
      margin: "0px",
    },
  },
}));
const ServiceCard = ({ data }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.containerDesigner} container>
      <Grid
        item
        xs={12}
        md={3}
        style={{
          alignSelf: "center",
          justifyContent: "center",
          padding: "0px 0px 8px 0px",
          alignContent: "center",
        }}
      >
        <Box
          style={{
            alignSelf: "center",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <img
            src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
              data?.imageIcon,
              {}
            )}`}
            alt="icon"
            style={{
              height: "25px",
              width: "25px",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} style={{ padding: "0px 0px 8px 0px" }}>
        <Typography
          color="textSecondary"
          variant="h5"
          align="center"
          style={{
            fontWeight: "600",
            alignItems: "center",
            color: "#303030",
            margin: "10px 0px",
            fontSize: "0.75rem",
          }}
        >
          {data?.title}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ServiceCard;
