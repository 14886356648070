import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  resizeImage,
  getDisplayType,
  DisplayOrientation,
  ImageFit,
} from "../../helpers/resizeImage";
const CarouselWithResizedImage = ({
  imageList,
  alternateText,
  multiples, //multiples: [width_xs, width_md, height_xs, height_md],
  fit,
  bg,
}) => {
  let resizeInside = {
    width:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerWidth * multiples[0])
        : Math.round(window.innerWidth * multiples[1]),
    height:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerHeight * multiples[2])
        : Math.round(window.innerHeight * multiples[3]),
    fit: "inside",
  };

  let resizeContain = {
    width:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerWidth * multiples[0])
        : Math.round(window.innerWidth * multiples[1]),
    height:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerHeight * multiples[2])
        : Math.round(window.innerHeight * multiples[3]),
    fit: "contain",
    background: bg,
  };
  return (
    <Carousel
      showStatus={false}
      dynamicHeight={false}
      stopOnHover
      swipeable
      infiniteLoop
      autoPlay
      showArrows={false}
      style={{ margin: "1rem" }}
      //className={classes.carouselProductDetails}
    >
      {imageList?.map((item, idx) => {
        return (
          <img
            key={idx}
            src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(item?.img, {
              resize:
                fit === ImageFit.CONTAIN
                  ? resizeContain
                  : fit === ImageFit.INSIDE
                  ? resizeInside
                  : {
                      width:
                        getDisplayType() === DisplayOrientation.PORTRAIT
                          ? Math.round(window.innerWidth * multiples[0])
                          : Math.round(window.innerWidth * multiples[1]),
                      height:
                        getDisplayType() === DisplayOrientation.PORTRAIT
                          ? Math.round(window.innerHeight * multiples[2])
                          : Math.round(window.innerHeight * multiples[3]),
                      fit: "cover",
                    },
            })}`}
            alt={alternateText}
          />
        );
      })}
    </Carousel>
  );
};

export default CarouselWithResizedImage;
