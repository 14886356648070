import {
  resizeImage,
  getDisplayType,
  DisplayOrientation,
  ImageFit,
} from "../../helpers/resizeImage";
const ResizedImage = ({
  item,
  alternateText,
  multiples, //multiples: [width_xs, width_md, height_xs, height_md],
  fit,
  bg,
  customStyle
}) => {
  let resizeInside = {
    width:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerWidth * multiples[0])
        : Math.round(window.innerWidth * multiples[1]),
    height:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerHeight * multiples[2])
        : Math.round(window.innerHeight * multiples[3]),
    fit: "inside",
  };

  let resizeContain = {
    width:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerWidth * multiples[0])
        : Math.round(window.innerWidth * multiples[1]),
    height:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? Math.round(window.innerHeight * multiples[2])
        : Math.round(window.innerHeight * multiples[3]),
    fit: "contain",
    background: bg,
  };
  return (
    <img
      style={customStyle}
      width={
        getDisplayType() === DisplayOrientation.PORTRAIT
          ? Math.round(window.innerWidth * multiples[0])
          : Math.round(window.innerWidth * multiples[1])
      }
      height={
        getDisplayType() === DisplayOrientation.PORTRAIT
          ? Math.round(window.innerHeight * multiples[2])
          : Math.round(window.innerHeight * multiples[3])
      }
      src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(item, {
        resize:
          fit === ImageFit.CONTAIN
            ? resizeContain
            : fit === ImageFit.INSIDE
            ? resizeInside
            : {
                width:
                  getDisplayType() === DisplayOrientation.PORTRAIT
                    ? Math.round(window.innerWidth * multiples[0])
                    : Math.round(window.innerWidth * multiples[1]),
                height:
                  getDisplayType() === DisplayOrientation.PORTRAIT
                    ? Math.round(window.innerHeight * multiples[2])
                    : Math.round(window.innerHeight * multiples[3]),
                fit: "cover",
              },
      })}`}
      alt={alternateText}
    />
  );
};

export default ResizedImage;
