import { makeStyles, Typography, Box, Grid, Divider } from "@material-ui/core";
import { useParams } from "react-router";
import CommonLayout from "../../layouts/CommonLayout";
import { useQuery } from "@apollo/client";
import { GET_DESIGNER_DETAILS } from "../../graphql/Queries";
import { resizeImage } from "../../helpers/resizeImage";
import { Link as RouterLink } from "react-router-dom";
import ResizedImage from "../../components/ResizedImage";
import { ImageFit } from "../../helpers/resizeImage";
import { hexToRGB } from "../../helpers/hexToRGB";
import colors from "../../theme/values/colors.json";
import { ShareSocial } from 'react-share-social';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  textTitleByInterior: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "48px",
  },
  containerDesigner: {
    padding: "32px",
  },
  containerCard: {
    minWidth: "275px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      minWidth: "inherit",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  containerCardText: {
    color: "#303030",
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    padding: "16px 0px",
    [theme.breakpoints.down("md")]: {
      minWidth: "inherit",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  shareDesignerProfile: {
    "& .makeStyles-copyIcon-14": {
      color: theme.palette.secondary.main
    }
  }
}));

const DesignerProfileView = () => {
  const { seoName } = useParams();
  const classes = useStyles();
  const { data, error } = useQuery(GET_DESIGNER_DETAILS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      seoName: seoName,
    },
    onCompleted: (data) => {
      console.log("on completed of designer details", data);
    },
    onError: () => {
      console.log(error);
    },
  });
  return (
    <>
      <Helmet>
        <title>{data?.designerDetail?.customerProfile?.name}</title>

        <meta name="description" content={data?.designerDetail?.profession + "\n" + data?.designerDetail?.education
          + "\n" + data?.designerDetail?.aboutMe} />
        <meta name="title" content={data?.designerDetail?.customerProfile?.name} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={data?.designerDetail?.customerProfile?.name} />
        <meta property="og:description" content={data?.designerDetail?.profession + "\n" + data?.designerDetail?.education
          + "\n" + data?.designerDetail?.aboutMe} />
        <meta property="og:image" content={process.env.REACT_APP_CLOUD_FRONT + resizeImage(data?.designerDetail?.customerProfile?.photo, {})} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={data?.designerDetail?.customerProfile?.name} />
        <meta property="twitter:description" content={data?.designerDetail?.profession + "\n" + data?.designerDetail?.education
          + "\n" + data?.designerDetail?.aboutMe} />
        <meta property="twitter:image" content={process.env.REACT_APP_CLOUD_FRONT + resizeImage(data?.designerDetail?.customerProfile?.photo, {})} />
      </Helmet>
      <CommonLayout>
        <Grid className={classes.containerDesigner} container>
          <Grid
            item
            xs={12}
            md={2}
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              padding: "16px",
            }}
          >
            <img
              src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
                data?.designerDetail?.customerProfile?.photo,
                {}
              )}`}
              alt="icon"
              style={{
                height: "50%",
                width: "50%",
                borderRadius: "50%",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "16px 0px 8px 0px" }}>
            <Grid item xs={12} md={12} style={{ padding: "12px 0px 8px 0px" }}>
              <Typography
                color="textSecondary"
                variant="h5"
                align="left"
                style={{
                  color: "#303030",
                  fontSize: "2.5rem",
                  alignItems: "left",
                }}
              >
                {data?.designerDetail?.customerProfile?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: "12px 0px 8px 0px" }}>
              <Typography
                color="textSecondary"
                variant="h5"
                align="inherit"
                style={{
                  color: "#F77631",
                  fontSize: "1.25rem",
                  lineHeight: "137.1%",
                  fontWeight: "500",
                  flex: "none",
                  order: "1",
                  alignSelf: "stretch",
                  flexGrow: 0,
                }}
              >
                {data?.designerDetail?.profession}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: "12px 0px 8px 0px" }}>
              <Typography
                color="textSecondary"
                variant="h5"
                align="inherit"
                style={{
                  color: "#696866",
                  fontSize: "1.25rem",
                  lineHeight: "1.5rem",
                  fontWeight: "500",
                }}
              >
                {data?.designerDetail?.education}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className={classes.shareDesignerProfile}>
            <Typography color="textSecondary" variant="h5">Check out my designer profile!</Typography>
            <ShareSocial
              style={{
                background: colors.white1,
                borderRadius: 3,
                border: 0,
                color: colors.black1,
                padding: "0px 0px"
              }}
              url={`${process.env.REACT_APP_WEBSITE_URL}designer/${seoName}`}
              socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid className={classes.containerDesigner} container>
          <Grid item xs={12} md={2}>
            <Typography
              color="textSecondary"
              variant="h5"
              align="inherit"
              style={{
                color: "#303030",
                fontSize: "1.5rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "16px"
              }}
            >
              About Me
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            style={{
              width: "100%",
            }}
          >
            <Typography
              color="textSecondary"
              variant="h5"
              align="inherit"
              style={{
                color: "#494949",
                fontWeight: "400",
                flex: "none",
                order: "1",
                alignSelf: "stretch",
                lineHeight: "2.25rem",
                flexGrow: 0,
              }}
            >
              {data?.designerDetail?.aboutMe ? data?.designerDetail?.aboutMe : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.containerDesigner} container>
          <Grid item xs={12} md={2}>
            <Typography
              color="textSecondary"
              variant="h5"
              align="inherit"
              style={{
                color: "#303030",
                fontSize: "1.5rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "16px",
              }}
            >
              My works
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Grid container>
              {data?.designerDetail?.designedProducts?.map((product, idx) => {
                return (
                  <Grid item xs={12} md={4} key={idx}>
                    <RouterLink to={`/product/${product?.seoName}`}>
                      <Grid container>
                        <Grid item xs={12} md={12} className={classes.containerCard}>
                          <Box>
                            {/* <img
                              src={`${
                                process.env.REACT_APP_CLOUD_FRONT
                              }${resizeImage(product?.images[0].img, {})}`}
                              alt="productImage"
                              style={{
                                height: "220px",
                                width: "275px",
                              }}
                            /> */}
                            <ResizedImage
                              item={product?.images[0]?.img}
                              alternateText={product?.seoName}
                              multiples={[0.75, 0.175, 0.35, 0.3]}
                              fit={ImageFit.CONTAIN}
                              bg={hexToRGB(colors.white1)}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.containerCardText}
                        >
                          <Typography
                            color="textSecondary"
                            variant="h5"
                          >
                            {product?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </RouterLink>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </CommonLayout>
    </>
  );
};

export default DesignerProfileView;
