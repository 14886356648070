import { useState } from "react";
import { Menu, MenuItem, IconButton, makeStyles } from "@material-ui/core";
import { ChevronDown as KeyboardArrowDownIcon } from "react-feather";
import { logOut } from "../../helpers/loginStatus";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  iconBtnNavbar: {
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    minWidth: "0px",
    padding: "0px 0px 0px 8px",
  },
  menuNavbar: {
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      background: theme.palette.primary.main,
      color: theme.palette.background.default,
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: "0.9rem",
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          background: theme.palette.background.default,
        },
      },
    },
  },
}));
export default function CustomizedMenus() {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        className={classes.iconBtnNavbar}
        onClick={handleClick}
      >
        <KeyboardArrowDownIcon size="18" />
      </IconButton>
      <Menu
        className={classes.menuNavbar}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push("/profile")} disableRipple>
          Profile
        </MenuItem>
        <MenuItem onClick={() => history.push("/wishlist")} disableRipple>
          Wishlist
        </MenuItem>
        <MenuItem
          onClick={() => {
            logOut();
            history.push("/");
          }}
          disableRipple
        >
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
}
