import {
  Avatar,
  Grid,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { GET_ORDER_HISTORY } from "../../graphql/Queries";
import { useQuery } from "@apollo/client";
import { isLoggedIn } from "../../helpers/loginStatus";
import CommonLayout from "../../layouts/CommonLayout";
import OrderCard from "../../components/OrderCard";
//import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  avatarLargeProfileIcon: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  containerOrderCard: {
    padding: theme.spacing(3),
  },
}));
const ProfileView = () => {
  const classes = useStyles();
  const { data: orderHistoryData } = useQuery(GET_ORDER_HISTORY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("On completed of order history", data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <CommonLayout>
      {/* <Helmet>   
        <title>Profile</title>
        <meta name="description" content="Checkout this product list of Inova Furniture Ltd ! " />
        <meta name="title" content="Profile" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ window.location.href } />
        <meta property="og:title" content="Profile" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={ window.location.href } />
        <meta property="twitter:title" content="Profile" />
      </Helmet> */}
      { isLoggedIn() ? (
        <>
          <Grid
            container
            alignItems="center"
            direction="column"
            style={ { padding: "24px 0px 0px 0px" } }
          >
            <Grid item>
              <Avatar
                variant="circular"
                className={ classes.avatarLargeProfileIcon }
              />
            </Grid>
            { localStorage.getItem("inova-customer") ? (
              <>
                <Grid item style={ { marginTop: "16px" } }>
                  <Typography variant="h3" color="primary">
                    { JSON.parse(localStorage.getItem("inova-customer"))?.name }
                  </Typography>
                </Grid>
                <Grid item style={ { marginTop: "8px" } }>
                  <Typography variant="h5" color="primary">
                    { JSON.parse(localStorage.getItem("inova-customer"))?.phone }
                  </Typography>
                </Grid>

                <Grid item style={ { marginTop: "8px" } }>
                  <Typography variant="h5" color="primary">
                    Referral Code:{ " " }
                    {
                      JSON.parse(localStorage.getItem("inova-customer"))
                        ?.referralCode
                    }
                  </Typography>
                </Grid>
                <Grid item style={ { marginTop: "8px" } }>
                  <Typography variant="h5" color="primary">
                    Points:{ " " }
                    { JSON.parse(localStorage.getItem("inova-customer"))?.points }
                  </Typography>
                </Grid>
              </>
            ) : (
              <></>
            ) }
          </Grid>
          <Divider style={ { margin: "8px 0px" } } />
          <Grid container>
            { orderHistoryData &&
              orderHistoryData?.orderHistory?.length !== 0 ? (
              <>
                <Grid item xs={ 12 } md={ 12 } container justifyContent="center">
                  <Typography variant="h4" color="primary">
                    Order History
                  </Typography>
                </Grid>
                { orderHistoryData?.orderHistory?.map((order, idx) => {
                  return (
                    <Grid
                      item
                      xs={ 6 }
                      md={ 3 }
                      className={ classes.containerOrderCard }
                    >
                      <OrderCard
                        orderId={ order?.id }
                        statusCode={ order?.status?.code }
                        paymentReceived={ order?.paymentReceived }
                      />
                    </Grid>
                  );
                }) }
              </>
            ) : (
              <></>
            ) }
          </Grid>
        </>
      ) : (
        <></>
      ) }
    </CommonLayout>
  );
};

export default ProfileView;
