export function isTextValid(str) {
  if (str.length !== 0) return true;
  else return false;
}

export function isPhoneNumberValid(phone) {
  const pattern = new RegExp(/^(?:\+88|88)?(01[3-9]\d{8})$/);
  return pattern.exec(phone) !== null;
}

export function isZipCodeValid(zip) {
  if (zip.length === 4) return true;
  else return false;
}

export function isEmailValid(email) {
  const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return pattern.exec(email) !== null;
  // return String(email)
  //   .toLowerCase()
  //   .match(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //   );
}
