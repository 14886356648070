//import { useState, useEffect } from "react";
import {
  Box,
  //Container,
  //Typography,
  //Dialog
} from "@material-ui/core";
//import { getDisplayType, DisplayOrientation } from "../../helpers/resizeImage";
import CommonLayout from "../../layouts/CommonLayout";
import HomeCarousel from "../../components/HomeCarousel";
import BySpace from "../../components/BySpace";
import ByInterior from "../../components/ByInterior";
import ProductCategories from "../../components/ProductCategories";
import { GET_HOME_FEED } from "../../graphql/Queries";
import { useQuery } from "@apollo/client";
import { Helmet } from 'react-helmet';
import ExtraBanner from "../../components/ExtraBanner";

//import LottieAnimation from "../../helpers/lottie";
//import UnderMaintenanceAnimation from "../../animations/under-construction.json";
//import CustomDialog from "../../components/CustomDialog";
const HomeView = () => {
  //const [showAnimation, setShowAnimation] = useState();
  const { data, error } = useQuery(GET_HOME_FEED, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("on completed of home", data);
    },
    onError: () => {
      console.log(error);
    },
  });
  console.log(data?.activeHeroBanners?.filter((item) => JSON.parse(item.meta).extra));
  const DESCRIPTION = `INOVA is a concern of SHATADAL GROUP. SHATADAL means Lotus, the flower
  with hundred petals. The companies in the group are like petals of a
  flower. INOVA has set its course to create a new aesthetic standard
  for all working environments. Our long experience in handling projects helped us to understand that
  the needs of each business are unique. So the INOVA way is to work
  with our clients from start to completion, from design inception to
  installation. INOVA will be beside you for small requirements to large-scale project
  materialization. Support will continue from interior design solutions
  to create sophisticated lighting arrangements. Our trusted team will
  go through the entire process step-by-step and ensure that all
  decisions fit your work needs in the most cost-effective manner.`
  // useEffect(() => {
  //   setShowAnimation(true);
  // }, []);

  return (
    <CommonLayout>
      <Helmet>
        <title>Inova Furniture Ltd</title>

        <meta name="description" content={DESCRIPTION} />
        <meta name="title" content="Inova Furniture Ltd" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Inova Furniture Ltd" />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:image" content={process.env.REACT_APP_BRAND_LOGO} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content="Inova Furniture Ltd" />
        <meta property="twitter:description" content={DESCRIPTION} />
        <meta property="twitter:image" content={process.env.REACT_APP_BRAND_LOGO} />
      </Helmet>
      <Box>
        {data ? (
          <>
            {/* {showAnimation ? (
              <CustomDialog
                open={showAnimation}
                handlePositiveButtonAction={() => setShowAnimation(false)}
                positiveButtonText="Close"
                handleClose={() => setShowAnimation(false)}
              >
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth:  getDisplayType() === DisplayOrientation.PORTRAIT
                    ? parseInt(window.innerWidth * 0.8)
                    : parseInt(window.innerWidth * 0.4),
                    padding: "16px",
                  }}
                >
                  <Typography
                    variant="h4"
                    color="secondary"
                    style={{ fontWeight: "bold" }}
                  >
                    Welcome to our brand new website.
                  </Typography>
                  <LottieAnimation
                    lotti={UnderMaintenanceAnimation}
                    height={
                      getDisplayType() === DisplayOrientation.PORTRAIT
                      ? parseInt(window.innerHeight * 0.35)
                      : parseInt(window.innerHeight * 0.3)
                    }
                    width={
                      getDisplayType() === DisplayOrientation.PORTRAIT
                      ? parseInt(window.innerWidth * 0.8)
                      : parseInt(window.innerWidth * 0.3)
                    }
                  />
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{ 
                      fontWeight: "normal"
                    }}
                  >
                    While we are still working hard to make the site better for you, feel free to check out some of the exciting new features.
                  </Typography> */}
            {/* <LottieAnimation
                      lotti={UnderMaintenanceAnimation}
                      height={500}
                      width={500}
                    /> */}
            {/* </Container>
              </CustomDialog>
            ) : (
              <></>
            )} */}

            <HomeCarousel data={data?.activeHeroBanners?.filter((item) => !JSON.parse(item?.meta)?.extra)} />
            <BySpace data={data.allSpaces} />
            <ProductCategories data={data.categoryGraph} />
            <ByInterior data={data.allInteriors} />
            {data?.activeHeroBanners?.filter((item) => JSON.parse(item?.meta)?.extra)?.map((extraBanner, idx) => {
              return (
                <ExtraBanner key={idx} item={extraBanner} />
              )
            })}
          </>
        ) : (
          <> </>
        )}
      </Box>
    </CommonLayout>
  );
};

export default HomeView;
