import { makeStyles } from "@material-ui/core";
import { Box, Grid, Typography } from "@material-ui/core";
import ResizedImage from "../ResizedImage";
import { ImageFit } from "../../helpers/resizeImage";
import { Link as RouterLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  containerDesigner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    "&.MuiGrid-spacing-xs-3": {
      width: "inherit",
      margin: "inherit",
    },
    minHeight: "450px",
  },
}));
const DesignerCard = ({ data }) => {
  const classes = useStyles();
  return (
    <RouterLink to={`/designer/${data?.seoName}`}>
      <Grid className={classes.containerDesigner} container>
        <Grid item xs={12} md={3}>
          <Box
            style={{
              width: "100%",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100px",
              minWidth: "100%",
            }}
          >
            <ResizedImage
              item={data?.customerProfile?.photo}
              alternateText={data.title}
              multiples={[0.75, 0.175, 0.25, 0.3]}
              fit={ImageFit.CONTAIN}
              bg={"#fafafa"}
              customStyle={{
                borderRadius: "10%",
                margin: "12px"
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} style={{ paddingLeft: "16px" }}>
          <Typography
            color="textSecondary"
            variant="h5"
            align="inherit"
            style={{
              color: "#303030",
              fontSize: "1.5rem",
              fontWeight: "600",
            }}
          >
            {data?.customerProfile?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ paddingLeft: "16px" }}>
          <Typography
            color="textSecondary"
            variant="h5"
            align="inherit"
            style={{
              color: "#F77631",
              fontSize: "1rem",
              lineHeight: "137.1%",
              fontWeight: "500",
              flex: "none",
              order: "1",
              alignSelf: "stretch",
              flexGrow: 0,
            }}
          >
            {data.profession}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ padding: "0px 16px" }}>
          <Typography
            color="textSecondary"
            variant="h5"
            align="inherit"
            style={{
              fontSize: ".75rem",
              lineHeight: "1rem",
              fontWeight: "500",
              padding: "12px 0px"
            }}
          >
            {data.education}
          </Typography>
        </Grid>
        {/* <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ padding: "0px 0px 8px 0px" }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    color: "black",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    padding: "8px 16px 8px 16px",
                                    width: "100%",
                                    border: "1px solid black",
                                    borderRadius: "8px",
                                    opacity: "0",
                                }}
                            >
                                Request Contact Info

                            </Button>
                        </Grid> */}
      </Grid>
    </RouterLink>
  );
};
export default DesignerCard;
