import { createTheme } from "@material-ui/core/styles";
import colors from "./values/colors.json";
import typography from "./values/typography.json";
const breakpoints = {
  values: {
    xs: 0,
    sm: 0, // Phone
    md: 768, // Tablet/Laptop
    lg: 1500, // Desktop
    xl: 2000
  }
};
const theme = createTheme({
  palette: {
    background: {
      default: colors.white1,
    },
    primary: {
      main: colors.black1,
    },
    secondary: {
      main: colors.orange0,
    },
    text: {
      primary: colors.white1,
      secondary: colors.black1
    },
    info: {
      main: colors.orange0,
    },
  },
  breakpoints,
  typography: {
    fontFamily: typography.fontFamily.join(","),
    h1: {
      fontSize: typography.h1,
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: typography.h1_small
      }
    },
    h2: {
      fontSize: typography.h2,
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: typography.h2_small
      }
    },
    h3: {
      fontSize: typography.h3,
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: typography.h3_small
      }
    },
    h4: {
      fontSize: typography.h4,
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: typography.h4_small
      }
    },
    h5: {
      fontSize: typography.h5,
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: typography.h5_small
      }
    },
    body1: {
      fontSize: typography.body1,
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: typography.body1_small
      }
    },
  },
});

export default theme;
