import { BrowserRouter as Router, Route } from "react-router-dom";
import GlobalStyles from "./theme/global";
import SignupView from "./views/RegisterView";
import LoginView from "./views/LoginView";
import HomeView from "./views/HomeView";
import ProductListView from "./views/ProductListView";
import ProductDetailsView from "./views/ProductDetailsView";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import CheckoutView from "./views/CheckoutView";
import PaymentStatusView from "./views/PaymentStatusView";
import WishlistView from "./views/WishlistView";
import ProfileView from "./views/ProfileView";
import ScrollToTop from "./hooks/scrollToTop";
import DesignersView from "./views/DesignersView";
import DesignerProfileView from "./views/DesignerProfileView";

const App = () => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_SERVER_URL,
  });
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("inova-token");
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
      },
    };
  });
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
    link: authLink.concat(httpLink),
  });
  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop>

          {/** Public Routes */}
          <Route path="/" exact component={HomeView} />
          <Route path="/products" component={ProductListView} />
          <Route path="/product/:seoName" component={ProductDetailsView} />
          <Route path="/register" exact component={SignupView} />
          <Route path="/login" exact component={LoginView} />
          <Route path="/designers" exact component={DesignersView} />
          <Route path="/designer/:seoName" exact component={DesignerProfileView} />

          {/** Private Routes */}
          <Route path="/checkout" exact component={CheckoutView} />
          <Route path="/checkout/:status" component={PaymentStatusView} />
          <Route path="/wishlist" component={WishlistView} />
          <Route path="/profile" component={ProfileView} />
          <GlobalStyles />
        </ScrollToTop>
      </Router>
    </ApolloProvider>
  );
};

export default App;
