import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../theme/values/colors.json";
import typography from "../../theme/values/typography.json";

export const Nav = styled.nav`
  background: ${colors.black1};
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 3rem;
  z-index: 12;
  @media screen and (max-width: 768px) {
    padding: 0.2rem 0rem;
    justify-content: center;
  }
`;

export const NavLink = styled(Link)`
  color: ${colors.white1};
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: ${typography.fontFamily.join(",")};
  padding: 0 3rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: ${colors.white1};
    opacity: 0.9;
  }
`;

export const NavLinkWeb = styled(Link)`
  color: ${colors.white1};
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: ${typography.fontFamily.join(",")};
  padding: 0 3rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: ${colors.white1};
    opacity: 0.9;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: ${colors.orange0};
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: 768px) {
    margin-right: 16px;
    position: absolute;
    right: 0;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: ${colors.black1};
  padding: 10px 22px;
  color: ${colors.white1};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${colors.white1};
    color: ${colors.orange0};
  }
`;
