import { Grid, Box, Typography, Avatar } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
const ReviewCard = ({ userName, rating, review }) => {
  return (
    <Box style={{ padding: "16px" }}>
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "6px",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container direction="row">
            <Avatar />
            <Typography
              gutterBottom
              color="textSecondary"
              variant="h5"
              style={{ padding: "8px" }}
            >
              {userName}
            </Typography>
          </Grid>
          <Box component="fieldset" mb={1} borderColor="transparent">
            <Rating name="read-only" value={rating} readOnly />
          </Box>
          <Typography color="textSecondary" variant="body1">
            {review}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewCard;
