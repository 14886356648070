import { Typography, makeStyles, TextField, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerInvoiceReferralTitle: {
    padding: "0px 0px 0px 16px",
  },
  form: {
    padding: "16px 0px 0px 16px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    "& .MuiInputBase-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontSize: "18px",
      paddingLeft: theme.spacing(2),
    },
    "& .MuiFilledInput-input": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      width: 300,
      borderRadius: 4,
      paddingLeft: theme.spacing(3.5),
      fontSize: "16px",
    },
  },
}));
const AddReferralCode = ({ setReferralCode }) => {
  const classes = useStyles();
  return (
    <Grid container display="flex" alignItems="center" direction="column">
      <Typography
        variant="h4"
        className={classes.containerInvoiceReferralTitle}
      >
        Add Referral Code
      </Typography>
      <form className={classes.form}>
        <TextField
          variant="filled"
          type="text"
          InputLabelProps={{ color: "primary" }}
          id="referralCode"
          label="Referral Code"
          onChange={(e) => setReferralCode(e.target.value)}
        />
      </form>
    </Grid>
  );
};

export default AddReferralCode;
