import { useMutation, useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { useState } from "react";
import { ADD_ADDRESS } from "../../graphql/Mutations";
import { GET_ALL_CITIES } from "../../graphql/Queries";
import CustomDialog from "../../components/CustomDialog";
import {
  isTextValid,
  isPhoneNumberValid,
  isZipCodeValid,
} from "../../helpers/validators";
import CustomForm from "../CustomForm";

const AddressCreationForm = ({
  showForm,
  setAddressCreateSuccess,
  showAddressCreationForm,
  setShowAddressCreationForm,
}) => {
  const [newAddress, setNewAddress] = useState({
    addressLine: "",
    label: "",
    cityId: -1,
    phone: "",
    zipCode: 0,
  });

  const [addAddress] = useMutation(ADD_ADDRESS, {
    variables: newAddress,
    onCompleted: (data) => {
      console.log("On completed of new address creation", data);
      setAddressCreateSuccess((prev) => prev + 1);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  //TODO: CityID is hardcoded now, for only Dhaka
  const { data: cityData } = useQuery(GET_ALL_CITIES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("on completed of get all cities", data, cityData);

      const dhakaCityNameLowerCase = "dhaka";
      const dhakaCityId = data.allCities.filter((city) => {
        return city.name.toLowerCase() === dhakaCityNameLowerCase;
      })[0];
      console.log(dhakaCityId?.id);

      setNewAddress((prev) => ({
        ...prev,
        cityId: dhakaCityId && dhakaCityId?.id ? dhakaCityId?.id : -1,
      }));
    },
    onError: (error) => {
      console.log("Error in fetching all cities", error);
    },
  });

  return (
    <CustomDialog
      open={showAddressCreationForm}
      handlePositiveButtonAction={() => {
        if (
          isTextValid(newAddress.label) &&
          isTextValid(newAddress.addressLine) &&
          isPhoneNumberValid(newAddress.phone)
        ) {
          addAddress();
          showForm(false);
        }
      }}
      positiveButtonText="Add Address"
      handleNegativeButtonAction={() => setShowAddressCreationForm(false)}
      negativeButtonText="Close"
    >
      <CustomForm>
        <TextField
          variant="filled"
          type="text"
          InputLabelProps={{ color: "primary" }}
          id="label"
          label="Label"
          required
          error={!isTextValid(newAddress.label)}
          helperText={
            !isTextValid(newAddress.label) ? "This Field is Required" : " "
          }
          onChange={(e) =>
            setNewAddress((prev) => ({
              ...prev,
              label: e.target.value,
            }))
          }
        />
        <TextField
          variant="filled"
          type="text"
          InputLabelProps={{ color: "primary" }}
          id="line"
          label="Address"
          required
          error={!isTextValid(newAddress.addressLine)}
          helperText={
            !isTextValid(newAddress.addressLine)
              ? "This Field is Required"
              : " "
          }
          onChange={(e) =>
            setNewAddress((prev) => ({
              ...prev,
              addressLine: e.target.value,
            }))
          }
        />
        <TextField
          variant="filled"
          type="number"
          InputLabelProps={{ color: "primary" }}
          id="zipCode"
          required
          label="Zip Code"
          error={!isZipCodeValid(newAddress.zipCode)}
          helperText={
            !isZipCodeValid(newAddress.zipCode) ? "Enter a valid zip code" : " "
          }
          onChange={(e) =>
            setNewAddress((prev) => ({
              ...prev,
              zipCode: e.target.value,
            }))
          }
        />
        <TextField
          variant="filled"
          type="text"
          disabled
          InputLabelProps={{ color: "primary", shrink: true }}
          id="City"
          label="City"
          value={`Dhaka`}
          //onChange={(e) => setUsername(e.target.value)}
        />
        {/* <TextField
          variant="filled"
          select
          id="city"
          label="City"
          value={1}
          SelectProps={{
            native: true,
          }}
          //onChange={(e) => setUsername(e.target.value)}
        >
          {allCities.map((option) => (
            <Typography color="primary" variant="body1">
              {option.name}
            </Typography>
            // <MenuItem key={option.id} value={option.id}>
            //   {option.name}
            // </MenuItem>
          ))}
        </TextField> */}
        <TextField
          variant="filled"
          type="text"
          InputLabelProps={{ color: "primary" }}
          id="phone"
          required
          label="Phone"
          error={!isPhoneNumberValid(newAddress.phone)}
          helperText={
            !isPhoneNumberValid(newAddress.phone)
              ? "Enter a valid phone number"
              : " "
          }
          onChange={(e) =>
            setNewAddress((prev) => ({
              ...prev,
              phone: e.target.value,
            }))
          }
        />
      </CustomForm>
    </CustomDialog>
  );
};

export default AddressCreationForm;
