import { gql } from "@apollo/client";

export const SIGNUP = gql`
  mutation signup(
    $firebaseIdtoken: String!
    $name: String!
    $username: String!
    $phone: String!
    $password: String!
    $newsletterSubscription: Boolean
  ) {
    signUp(
      firebaseIdtoken: $firebaseIdtoken
      name: $name
      username: $username
      phone: $phone
      password: $password
      newsletterSubscription: $newsletterSubscription
    ) {
      user {
        username
        firstName
        lastName
      }
      customer {
        name
        phone
        referralCode
        points
        user {
          username
          password
        }
        addresses {
          id
          addressLine
          city {
            name
          }
          zipCode
          phone
          label
        }
        wishlistproductsOfThisCustomer {
          product {
            id
            seoName
            name
            unitPrice
            images {
              img
            }
          }
        }
      }
      token
    }
  }
`;

export const SIGNUPV2 = gql`
  mutation signupv2(
    $authMethod: String!
    $firebaseIdtoken: String!
    $name: String!
    $username: String!
    $phone: String
    $email: String
    $newsletterSubscription: Boolean
  ) {
    signUpV2(
      authMethod: $authMethod
      firebaseIdtoken: $firebaseIdtoken
      name: $name
      username: $username
      phone: $phone
      email: $email
      newsletterSubscription: $newsletterSubscription
    ) {
      user {
        username
        firstName
        lastName
      }
      customer {
        name
        phone
        referralCode
        points
        user {
          username
          password
        }
        addresses {
          id
          addressLine
          city {
            name
          }
          zipCode
          phone
          label
        }
        wishlistproductsOfThisCustomer {
          product {
            id
            seoName
            name
            unitPrice
            images {
              img
            }
          }
        }
      }
      token
    }
  }
`;

export const LOGIN = gql`
  mutation signin($username: String!, $password: String!) {
    signIn(username: $username, password: $password) {
      token
      payload
    }
  }
`;

export const LOGINV2 = gql`
  mutation signinv2(
    $authMethod: String!
    $firebaseIdtoken: String!
    $username: String!
    $phone: String
    $email: String
    ) {
    signInV2(
      authMethod: $authMethod
      firebaseIdtoken: $firebaseIdtoken
      username: $username
      phone: $phone
      email: $email
      ) {
        user {
          username
          firstName
          lastName
        }
        customer {
          name
          phone
          referralCode
          points
          user {
            username
            password
          }
          addresses {
            id
            addressLine
            city {
              name
            }
            zipCode
            phone
            label
          }
          wishlistproductsOfThisCustomer {
            product {
              id
              seoName
              name
              unitPrice
              images {
                img
              }
            }
          }
        }
        token
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation addOrUpdateToCart($productId: Int!, $qty: Int!) {
    addOrUpdateToCart(productId: $productId, qty: $qty) {
      cart {
        cartproductsOfThisCart {
          product {
            id
            name
          }
          qty
        }
      }
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation removeFromCart($productId: Int!) {
    removeFromCart(productId: $productId) {
      cart {
        cartproductsOfThisCart {
          product {
            name
            id
          }
        }
      }
    }
  }
`;

export const ADD_TO_WISHLIST = gql`
  mutation addToWishlist($productId: Int!) {
    addToWishlist(productId: $productId) {
      wishlist {
        product {
          id
          seoName
          name
        }
      }
    }
  }
`;

export const REMOVE_FROM_WISHLIST = gql`
  mutation removeFromWishlist($productId: Int!) {
    removeFromWishlist(productId: $productId) {
      wishlist {
        product {
          id
          seoName
          name
        }
      }
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

export const ADD_ORDER = gql`
  mutation addOrder($addressId: Int!, $referralCode: String) {
    addOrder(addressId: $addressId, referralCode: $referralCode) {
      order {
        id
        invoice {
          id
          totalAmount
        }
      }
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation addAddress(
    $addressLine: String!
    $label: String!
    $cityId: Int!
    $phone: String!
    $zipCode: Int!
  ) {
    addAddress(
      addressLine: $addressLine
      label: $label
      cityId: $cityId
      phone: $phone
      zipCode: $zipCode
    ) {
      addresses {
        id
        addressLine
        label
      }
    }
  }
`;

export const APPLY_COUPON = gql`
  mutation applyCoupon($couponName: String!) {
    applyCoupon(couponName: $couponName) {
      isSuccess
      message
    }
  }
`;

export const INIT_PAYMENT = gql`
  mutation initPayment($orderId: Int!) {
    initPayment(orderId: $orderId) {
      response
    }
  }
`;

export const ADD_REVIEW = gql`
  mutation addReview($productId: Int!, $rating: Int!, $review: String!) {
    addReview(productId: $productId, rating: $rating, review: $review) {
      isSuccess
    }
  }
`;
