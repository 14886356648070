export function handleAsyncCalls(client, query, handleThen, vars) {
  client
    .query({
      query: query,
      variables: vars ? vars : null,
      fetchPolicy: "no-cache",
    })
    .then((data) => {
      handleThen(data);
    })
    .catch((err) => {
      console.log(err);
    });
}