import { Box, Container, Grid, Typography } from "@material-ui/core";
import {
  getDisplayType,
  DisplayOrientation,
  resizeImage,
} from "../../helpers/resizeImage";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textTitleByInterior: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "48px",
  },
}));
const ByInterior = ({ data }) => {
  const classes = useStyles();
  return (
    <Box
      id="by-interiors"
      style={{
        paddingTop: "64px",
        marginBottom: "64px",
        width: "inherit",
        height: "inherit",
      }}
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <Container className={classes.textTitleByInterior}>
            <Typography color="textSecondary" variant="h4">
              Search all products
            </Typography>
            <Typography color="textSecondary" variant="h1">
              By Interior
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            {data?.slice(0, 4).map((item, idx) => {
              return (
                <Grid
                  item
                  key={idx}
                  xs={6}
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: idx % 2 !== 0 ? "80px" : "0px",
                    paddingBottom: idx % 2 !== 0 ? "0px" : "80px",
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    style={{
                      position: "absolute",
                      paddingTop: "16px",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <RouterLink to={`/products?interior=${item?.seoName}`}>
                    <img
                      style={{ borderRadius: "5px" }}
                      src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
                        item.images[0]?.img,
                        {
                          resize: {
                            width: 150,
                            height: 500,
                            fit: "cover",
                          },
                          grayscale:
                            getDisplayType() === DisplayOrientation.PORTRAIT
                              ? false
                              : true,
                        }
                      )}`}
                      onMouseOver={(e) =>
                        (e.currentTarget.src = `${
                          process.env.REACT_APP_CLOUD_FRONT
                        }${resizeImage(item.images[0]?.img, {
                          resize: {
                            width: 150,
                            height: 500,
                            fit: "cover",
                          },
                        })}`)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src = `${
                          process.env.REACT_APP_CLOUD_FRONT
                        }${resizeImage(item.images[0]?.img, {
                          resize: {
                            width: 150,
                            height: 500,
                            fit: "cover",
                          },
                          grayscale:
                            getDisplayType() === DisplayOrientation.PORTRAIT
                              ? false
                              : true,
                        })}`)
                      }
                      alt="by-interior"
                    />
                  </RouterLink>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ByInterior;
