import {
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Box,
} from "@material-ui/core";
import { Facebook, Linkedin, Instagram } from "react-feather";
import addresses from "../../data/addresses.json";
import { DisplayOrientation, getDisplayType } from "../../helpers/resizeImage";

const useStyles = makeStyles((theme) => ({
  containerFooterBody: {
    background: theme.palette.primary.main,
    padding: "3rem",
    //position: "sticky",
    margin: "96px 0px 0px 0px",
    bottom: 0,
  },
  containerFooterAbout: {
    padding: "0px 8px 16px 0px",
  },
  containerFooterCompany: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: "32px"
  },
  containerFooterSocial: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: "16px"
  },
  containerFooterDesigner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    marginBottom: "16px"
  },
  containerFooterShowroom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginTop: "64px",
      alignItems: "flex-start",
    },
  },
  containerFooterPrivacy: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    [theme.breakpoints.down("md")]: {
      marginTop: "16px",
      alignItems: "left",
    },
  },
  textFooterSocial: {
    margin: "24px 0px 4px 0px",
    [theme.breakpoints.down("md")]: {
      marginTop: "16px",
    },
  },
  textFooterSectionTitle: {
    fontWeight: "bold",
    margin: "0px 0px 8px 0px",
  },
  textFooterSectionBody: {
    opacity: 0.6,
    margin: "0px 0px 4px 0px"
  },
  textFooterSectionBodyShowroom: {
    opacity: 0.6,
    padding: "12px 0px 12px 4px",
    textAlign: "right",
  },
  textFooterPrivacy: {
    opacity: 0.6,
    margin: "0px 0px 0px 32px",
  },
  imgFooterBrandLogo: {
    margin: "0px 0px 8px 0px",
  },
  iconBtnFooterSocial: {
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.containerFooterBody} id="footer">
      <Grid item xs={12} md={12} className={classes.containerFooterCompany}>
        <img
          width="120px"
          height="50px"
          src={`${process.env.REACT_APP_BRAND_LOGO}`}
          alt="inova"
          className={classes.imgFooterBrandLogo}
        />
        <img
          width="170px"
          height="15px"
          src="/inova-subtitle.svg"
          alt="inova"
        />
      </Grid>
      <Grid item xs={12} md={7} className={classes.containerFooterAbout}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSectionTitle}
        >
          About INOVA
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSectionBody}
        >
          INOVA Furniture Ltd. is a firm focused on enhancing the client
          experience when buying furniture. INOVA has set out to build a new
          aesthetic standard for all spaces, with a team of affiliated
          architects working behind the scenes to ensure creative and artistic
          product designs and a warm and approachable sales team to ensure the
          needs of our clientele are met.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSectionBody}
        >
          In most people's lives, furniture is a significant investment. We
          value people's investments and their desire to live in a sophisticated
          environment at INOVA. We always put our customers' needs first,
          working hard to ensure maximum satisfaction, comfort, and utility for
          our dear customers, with state-of-the-art product designs certified by
          world-class architects, competitive prices, quality assurance, and
          great after-delivery services.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSectionBody}
        >
          Our long experience in handling projects helped us to understand that
          the needs of each business are unique. So the INOVA way is to work
          with our clients from start to completion, from design inception to
          installation. INOVA will be beside you for small requirements to
          large-scale project materialization. Support will continue from retail
          sales to interior design solutions. Our trusted team will go through
          the entire process step-by-step and ensure that all decisions fit your
          work needs in the most cost-effective manner.
        </Typography>

      </Grid>
      <Grid item xs={12} md={5} className={classes.containerFooterShowroom}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSectionTitle}
        >
          Factory Address:
        </Typography>
        <Grid container>
          {addresses?.factories?.map((item, idx) => {
            return (
              <Grid
                item
                key={idx}
                xs={12}
                md={12}
                container
                className={classes.textFooterSectionBodyShowroom}
              >
                <Grid item xs={12} md={12}>
                  <Typography variant="body1" color="textPrimary">
                    {item?.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="body1" color="textPrimary">
                    {item?.addressLine}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSectionTitle}
        >
          Office Address:
        </Typography>
        <Grid container>
          {addresses?.offices?.map((item, idx) => {
            return (
              <Grid
                item
                key={idx}
                xs={12}
                md={12}
                container
                className={classes.textFooterSectionBodyShowroom}
              >
                <Grid item xs={12} md={12}>
                  <Typography variant="body1" color="textPrimary">
                    {item?.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="body1" color="textPrimary">
                    {item?.addressLine}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

      </Grid>
      <Grid item xs={6} md={6} className={classes.containerFooterSocial}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSocial}
        >
          Social Media
        </Typography>
        <Box>
          {/* eslint-disable-next-line */}
          <a
            target="_blank"
            href={"https://www.facebook.com/inovafurnitureltd"}
          >
            <IconButton className={classes.iconBtnFooterSocial}>
              <Facebook size="20" />
            </IconButton>
          </a>
          {/* eslint-disable-next-line */}
          <a
            target="_blank"
            href={"https://www.instagram.com/inovafurnitureltd/"}
          >
            <IconButton className={classes.iconBtnFooterSocial}>
              <Instagram size="20" />
            </IconButton>
          </a>
          {/* eslint-disable-next-line */}
          <a
            target="_blank"
            href={"https://www.linkedin.com/company/inova-furniture/"}
          >
            <IconButton className={classes.iconBtnFooterSocial}>
              <Linkedin size="20" />
            </IconButton>
          </a>
        </Box>
      </Grid>
      <Grid item xs={6} md={6} className={classes.containerFooterDesigner}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSocial}
        >
          Designed By
        </Typography>
        <img width="96px" height="40px" src="/LPC-logo.svg" alt="lpc" />
      </Grid>
      <Grid item xs={12} md={6} container alignItems="center">
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterSectionBody}
        >
          Copyright © 2021 Inova Furniture Limited. All rights reserved.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={classes.containerFooterPrivacy}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.textFooterPrivacy}
        >
          Trade License No: 009319
        </Typography>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://docs.google.com/document/d/10p-BhnPSV_frTz9kjndJnXbovSs9AVF-b5LMTUTbysU/edit?usp=sharing"}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.textFooterPrivacy}
          >
            Privacy Policy
          </Typography>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://docs.google.com/document/d/16V2wLPZmSJVn5CzbeGFCKy4pB3f5-pw7w_Ykbtdh28c/edit?usp=sharing"}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.textFooterPrivacy}
          >
            Terms of Use
          </Typography>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://docs.google.com/document/d/1BCzpK0mt7JRNRhiVjRTsdb5RmoW9IsxfK9b8SDWDw-E/edit?usp=sharing"}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.textFooterPrivacy}
          >
            Refund and Return Policy
          </Typography>
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        {getDisplayType() === DisplayOrientation.PORTRAIT ? (
          <img width="100%" src="/footer_ssl_mobile.png" alt="ssl" />
        ) : (
          <img width="100%" src="/footer_ssl_web.png" alt="ssl" />
        )}
      </Grid>
    </Grid>
  );
};

export default Footer;
