import { makeStyles, Typography, Box, Grid } from "@material-ui/core";
import DesignerCard from "../../components/DesignerCard";
import {
  GET_ALL_DESIGNERS,
  GET_ALL_PACKAGES,
  GET_ALL_SERVICES,
} from "../../graphql/Queries";
import CommonLayout from "../../layouts/CommonLayout";
import { useQuery } from "@apollo/client";
import WorkProcess from "../../components/WorkProcess";
import ServiceCard from "../../components/ServiceCard";
import PackageCard from "../../components/PackageCard";

const useStyles = makeStyles((theme) => ({
  textTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    //padding: "32px",
    color: "rgba(239, 65, 35, 1)",
    //background: "#fafafa",
  },
  container: {
    paddingTop: "32px",
    "@media (max-width: 500px)": {
      padding: "10px",
    },
    //background: "#fafafa",
  },
  button: {
    margin: "16px auto",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: "#303030",
    color: "#fff",
    //background: "#fafafa",
    borderRadius: "8px",
    marginBottom: "32px",
    opacity: "0",
  },
}));

const DesignersView = () => {
  const { data, error } = useQuery(GET_ALL_DESIGNERS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("on completed of get all designers", data, error);
    },
    onError: () => {
      console.log(error);
    },
  });
  const { data: serviceData } = useQuery(GET_ALL_SERVICES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("on completed of get all services", data, error);
    },
    onError: () => {
      console.log(error);
    },
  });
  const { data: PackageData } = useQuery(GET_ALL_PACKAGES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("on completed of get all packages", data, error);
    },
    onError: () => {
      console.log(error);
    },
  });

  const classes = useStyles();
  return (
    <CommonLayout>
      {/* designer card */}
      <Box
        id="by-affiliated-designers"
        style={{
          paddingTop: "64px",
          //backgroundColor: "#fafafa",
        }}
      >
        <Typography variant="h2" align="center" className={classes.textTitle}>
          Our Affiliated Designers
        </Typography>
        <Grid container className={classes.container}>
          {data ? (
            data.allDesigners.map((item, idx) => {
              return (
                <Grid item key={idx} xs={12} md={3} style={{ padding: "32px" }}>
                  <DesignerCard data={item} />
                </Grid>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
        {/* <Button variant="contained" className={classes.button}>
                    Become an Affiliated Designer
                </Button> */}
      </Box>
      {/* Work process */}
      <WorkProcess />
      <Box
        id="services"
        style={{
          paddingTop: "64px",
          //backgroundColor: "#fafafa",
        }}
      >
        <Typography variant="h2" align="center" className={classes.textTitle}>
          Our Services
        </Typography>
        <Grid
          container
          className={classes.container}
          style={{ padding: "0px 16px" }}
        >
          {serviceData ? (
            serviceData?.allServices?.map((item, idx) => {
              return (
                <Grid item key={idx} xs={12} md={4} style={{ padding: "16px" }}>
                  <ServiceCard data={item} />
                </Grid>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
      </Box>
      <Box
        id="services"
        style={{
          paddingTop: "64px",
          //backgroundColor: "#fafafa",
        }}
      >
        <Typography variant="h2" align="center" className={classes.textTitle}>
          Packages
        </Typography>
        <Grid container className={classes.container}>
          {PackageData ? (
            PackageData.allPackages?.map((item, idx) => {
              return (
                <Grid
                  item
                  key={idx}
                  xs={12}
                  md={4}
                  style={{
                    padding: "16px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PackageCard data={item} />
                </Grid>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </CommonLayout>
  );
};

export default DesignersView;
