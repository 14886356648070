import React from "react";
import { Nav, NavLink, NavMenu, NavBtn, NavLinkWeb } from "./elements";
import { Avatar, Typography, Button } from "@material-ui/core";
import CustomizedMenus from "../DropdownMenu";
import { useHistory } from "react-router";
import { DisplayOrientation, getDisplayType } from "../../helpers/resizeImage";
import { isLoggedIn } from "../../helpers/loginStatus";
import MobileNavDrawer from "../MobileNavDrawer";
import { GET_ALL_FILTERS } from "../../graphql/Queries";
import { useQuery } from "@apollo/client";
const Navbar = () => {
  const history = useHistory();
  const { data: allFilters, error: allFiltersError } = useQuery(
    GET_ALL_FILTERS,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted: (allFilters) => {
        console.log("on completed of get all filters in nav bar", allFilters);
      },
      onError: () => {
        console.log(allFiltersError);
      },
    }
  );
  return (
    <>
      <Nav>
        <NavMenu>
          <NavLink to="/">
            <img src={`${process.env.REACT_APP_BRAND_LOGO}`} alt="inova" />
          </NavLink>
          {/* <NavLinkWeb
            to="/"
            onClick={() => {
              document
                .getElementById("by-categories")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Typography variant="body1" color="textPrimary">
              All Products
            </Typography>
          </NavLinkWeb> */}
          {allFilters && allFilters?.allInteriors ? (
            <>
              {allFilters.allInteriors.map((item, idx) => {
                return (
                  <NavLinkWeb
                    to={`/products?interior=${item.seoName}`}
                    key={idx}
                  >
                    <Typography variant="body1" color="textPrimary">
                      {item.name}
                    </Typography>
                  </NavLinkWeb>
                );
              })}
            </>
          ) : (
            <></>
          )}
          <NavLinkWeb
            to={`/designers`}
          >
            <Typography variant="body1" color="textPrimary">
              {"Meet Our Designers"}
            </Typography>
          </NavLinkWeb>
          {/* <NavLinkWeb
            to="/"
            onClick={() => {
              document
                .getElementById("footer")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Typography variant="body1" color="textPrimary">
              About Us
            </Typography>
          </NavLinkWeb> */}
        </NavMenu>
        {getDisplayType() === DisplayOrientation.PORTRAIT ? (
          <NavBtn>
            <MobileNavDrawer dynamicOptions={allFilters?.allInteriors} />
          </NavBtn>
        ) : (
          <>
            {isLoggedIn() ? (
              <NavBtn>
                <Avatar style={{ width: "28px", height: "28px" }} />
                <Typography style={{ marginLeft: "8px" }} color="textPrimary">
                  {JSON.parse(localStorage.getItem("inova-customer"))?.name}
                </Typography>
                <CustomizedMenus />
              </NavBtn>
            ) : (
              <NavBtn>
                <Button
                  onClick={() => history.push("/login")}
                  variant="outlined"
                >
                  Sign In
                </Button>
              </NavBtn>
            )}
          </>
        )}
      </Nav>
    </>
  );
};

export default Navbar;
