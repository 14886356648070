import { Snackbar, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "1000px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const CustomSnackbar = (props) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={props.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={props.severity}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbar;
