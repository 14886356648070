import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { resizeImage } from "../../helpers/resizeImage";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import colors from "../../theme/values/colors.json";

const useStyles = makeStyles((theme) => ({
  outerBox: {
    background: theme.palette.primary.main,
    width: "inherit",
    height: "inherit",
  },
  textContainer: {
    padding: "32px 0px 32px 48px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "24px",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  buttonSpace: {
    borderRadius: "8px",
    marginTop: "24px",
    marginRight: "48px",
    textTransform: "capitalize",
    fontSize: "0.75rem",
    //padding: "12px 0px",
    //cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginRight: "24px",
    },
  },
  textHeadingSpace: {
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
  },
  containerSpaceRightOuter: {
    "&.MuiGrid-spacing-xs-3": {
      width: "inherit",
      margin: "inherit",
    },
  },
  containerSpaceRightInner: {
    paddingBottom: "32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center", //Better in flex-end
    "&.MuiGrid-spacing-xs-3": {
      padding: "inherit",
    },
  },
}));

const BySpace = ({ data }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(
    data && data.length > 0 ? data[0] : ""
  );
  if (!data) {
    return null;
  }
  return (
    <Box className={classes.outerBox} id="by-spaces">
      <Grid container>
        <Grid item xs={7} md={6} className={classes.textContainer}>
          <Typography color="textPrimary" variant="h4">
            Explore
          </Typography>
          <Typography
            className={classes.textHeadingSpace}
            color="textPrimary"
            variant="h1"
          >
            By Space
          </Typography>
          <Typography color="textPrimary" variant="h4">
            we have furnitures for every room, every inch
          </Typography>
        </Grid>
        <Grid item xs={5} md={1} className={classes.containerSpaceRightInner}>
          {data?.map((item, idx) => {
            return (
              <Button
                key={idx}
                onClick={() => setVisible(item)}
                className={classes.buttonSpace}
                style={{
                  background:
                    item.id === visible.id ? colors.orange0 : colors.white1,
                  width: item.id === visible.id ? "80%" : "70%",
                  color: item.id === visible.id ? colors.white0 : colors.black0,
                }}
              >
                {item.name}
              </Button>
            );
          })}
        </Grid>
        <Grid item xs={12} md={5}>
          <RouterLink
            to={`/products?space=${visible?.seoName}`}
            className={classes.imageContainer}
          >
            {/* <Typography
              color="textSecondary"
              variant="h2"
              style={{
                position: "absolute",
                paddingTop: "24px",
              }}
            >
              {visible?.name}
            </Typography> */}
            <img
              style={{ borderRadius: "6px 0px 0px 6px" }}
              src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
                visible?.images?.length !== 0 ? visible?.images[0]?.img : "",
                {
                  resize: {
                    width: 650,
                    height: 735,
                    fit: "cover",
                  },
                }
              )}`}
              width="100%"
              alt="by-space"
            />
          </RouterLink>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BySpace;
