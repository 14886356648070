import { useQuery } from "@apollo/client";
import { makeStyles, Typography, Box, Grid } from "@material-ui/core";
import { GET_ALL_WORK_PROCESS } from "../../graphql/Queries";

const useStyles = makeStyles((theme) => ({
  textTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
    color: "rgba(239, 65, 35, 1)",
    fontWeight: "500",
  },
  containerText: {
    width: "65%",
    display: "flex",
    alignSelf: "center",
    margin: "16px auto",
    backgroundColor: "rgba(255, 255, 255, 1)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const WorkProcess = () => {
  const classes = useStyles();
  const { data, error } = useQuery(GET_ALL_WORK_PROCESS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("on completed of get all work process", data, error);
    },
    onError: () => {
      console.log(error);
    },
  });
  return (
    <>
      <Box
        id="work-process"
        style={{
          paddingTop: "64px",
          paddingBottom: "16px",
          //backgroundColor: "#fafafa",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" align="center" className={classes.textTitle}>
          Want a custom order?
        </Typography>

        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          style={{ fontWeight: "500" }}
        >
          This is our work process:
        </Typography>

        {data?.allWorkProcesses?.map((item, idx) => {
          return (
            <Grid item xs={12} md={12} key={idx} className={classes.containerText}>
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "25px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0px 16px",
                  minHeight: "75px",
                  minWidth: "25px",
                }}
              >
                <Typography color="secondary">{item.position}</Typography>
              </Box>
              <Typography
                variant="h5"
                align="left"
                color="textSecondary"
                style={{
                  fontWeight: "500",
                  padding: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.description}
              </Typography>
            </Grid>
          );
        })}
      </Box>
    </>
  );
};
export default WorkProcess;
