export function hexToRGB(hex) {
  const opacity = 100;
  const str = (hex = hex.replace("#", ""))
    .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
    .map(function (l) {
      return parseInt(hex.length % 2 ? l + l : l, 16);
    })
    .concat(isFinite(opacity) ? opacity : 1)
    .join(",");

  //console.log(str);
  const rgba = str.split(",");
  return {
    r: parseInt(rgba[0]),
    g: parseInt(rgba[1]),
    b: parseInt(rgba[2]),
    alpha: parseInt(rgba[3])/100,
  };
}
