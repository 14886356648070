import { Typography, Grid, makeStyles, Button } from "@material-ui/core";
import { getDisplayType, DisplayOrientation } from "../../helpers/resizeImage";
import { useMutation } from "@apollo/client";
import { INIT_PAYMENT } from "../../graphql/Mutations";
const useStyles = makeStyles((theme) => ({
  containerOrderCard: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    borderRadius: "6px",
    textAlign: "center",
    height:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? window.innerHeight * 0.3
        : window.innerHeight * 0.16,
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
}));
const OrderCard = ({ orderId, statusCode, paymentReceived }) => {
  const classes = useStyles();
  let paid = "paid";

  const [initPayment] = useMutation(INIT_PAYMENT, {
    variables: {
      orderId: orderId,
    },
    onCompleted: (data) => {
      console.log("On completed of pay now", data);
      let gatewayUrl =
        JSON.parse(data?.initPayment?.response)?.GatewayPageURL || "";
      if (gatewayUrl !== "") window.open(gatewayUrl, "_blank");
    },
    onError: (error) => {
      console.log("Error in pay now", error);
    },
  });
  return (
    <Grid container className={classes.containerOrderCard}>
      <Typography variant="h5" color="secondary">
        Order ID: {orderId}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Status: {statusCode}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Paid Amount: {paymentReceived}
      </Typography>
      {!statusCode?.toLowerCase().startsWith(paid.toLowerCase()) ? (
        <Button
          color="secondary"
          variant="contained"
          size="large"
          style={{ marginTop: "4px" }}
          onClick={() => initPayment()}
        >
          Pay Now
        </Button>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default OrderCard;
