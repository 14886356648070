import { useState } from "react";
import { useHistory } from "react-router";
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Grid,
  makeStyles,
  Button,
  Badge,
} from "@material-ui/core";
import {
  ShoppingCart,
  ShoppingBag,
  Check,
  Trash2,
  CornerUpLeft,
  XCircle,
  Plus,
  Minus,
} from "react-feather";
import { useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { ADD_TO_CART, REMOVE_FROM_CART } from "../../graphql/Mutations";
import { GET_ACTIVE_CART } from "../../graphql/Queries";
import { isCartEmpty, isLoggedIn } from "../../helpers/loginStatus";
import ResizedImage from "../ResizedImage";
import { hexToRGB } from "../../helpers/hexToRGB";
import { ImageFit } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import CustomSnackbar from "../CustomSnackbar";
import { addToCartPage } from "../../helpers/googleAnalytics";

const useStyles = makeStyles((theme) => ({
  boxTempDrawerCart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: Math.round(window.innerWidth * 0.3),
    padding: "0px 16px",
    [theme.breakpoints.down("md")]: {
      width: Math.round(window.innerWidth),
    },
  },
  boxTitleTempDrawerCart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: Math.round(window.innerWidth * 0.3),
    height: "60px",
    marginBottom: "16px",
    background: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      width: Math.round(window.innerWidth),
    },
  },
  itemTempDrawerCart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
  },
  iconBtnPlusMinusCart: {
    background: colors.grey1,
    color: theme.palette.secondary.main,
    padding: "5px",
  },
  iconBtnCloseCart: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.background.default,
  },
  buttonTempDrawerCart: {
    width: "100%",
  },
  boxItemQuantityUpdateCart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  containerItemButtonCart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "8px",
    },
  },
  btnCheckoutCart: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: Math.round(window.innerWidth * 0.3),
    height: "50px",
    [theme.breakpoints.down("md")]: {
      width: Math.round(window.innerWidth),
    },
  },
  btnFloatingCart: {
    margin: 0,
    top: "auto",
    right: 30,
    bottom: 120,
    left: "auto",
    position: "fixed",
    minHeight: "2rem",
    minWidth: "2rem",
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    "&.MuiIconButton-root:hover": {
      background: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
      minHeight: "auto",
      bottom: 80,
    },
  },
  drawerCart: {
    "& .MuiPaper-root": {
      color: theme.palette.primary.main,
      background: theme.palette.background.default,
      width: Math.round(window.innerWidth * 0.3),
      [theme.breakpoints.down("md")]: {
        width: Math.round(window.innerWidth),
      },
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.background.default,
    },
  },
  textUnitPriceOverlayCartItem: {
    padding: "4px",
    borderRadius: "3px",
    position: "absolute",
    bottom: "0px",
    left: "50%",
    width: "100%",
    textAlign: "center",
    opacity: 0.8,
    background: colors.white2,
    transform: "translateX(-50%)",
    fontWeight: "bold",
  },
}));
const TemporaryDrawer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [snackOpen, setSnackOpen] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [cartData, setCartData] = useState([]);
  const [cartUpdateEvent, setCartUpdateEvent] = useState({
    productId: 0,
    qty: 0,
  });
  const [itemRemovalEvent, setItemRemovalEvent] = useState(0);
  const [cartUpdateSuccess, setCartUpdateSuccess] = useState(0);
  const [cartRemovalSuccess, setCartRemovalSuccess] = useState(0);
  const [openSideBar, setOpenSideBar] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const toggleSideBar = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSideBar(open);
    if (cartData.length === 0)
      setCartData(JSON.parse(localStorage.getItem("inova-cart")));
  };

  const applyCartChange = (item) => {
    if (item.isRemoved) setItemRemovalEvent(parseInt(item?.product?.id));
    else
      setCartUpdateEvent({
        productId: parseInt(item?.product?.id),
        qty: item.qty,
      });
  };

  const reduceQuantity = (item) => {
    var i = cartData.find((a) => a.id === item.id);
    var i_idx = cartData.findIndex((a) => a.id === item.id);
    const preQ = i.qty;
    const newQ = preQ === 1 ? 1 : preQ - 1;
    var newItem = {
      product: i.product,
      id: i.id,
      qty: newQ,
    };
    var arr = [...cartData];
    arr.splice(i_idx, 1, newItem);
    setCartData(arr);
  };

  const increaseQuantity = (item) => {
    var i = cartData.find((a) => a.id === item.id);
    var i_idx = cartData.findIndex((a) => a.id === item.id);
    const preQ = i.qty;
    const newQ = preQ + 1;
    var newItem = {
      product: i.product,
      id: i.id,
      qty: newQ,
    };
    var arr = [...cartData];
    arr.splice(i_idx, 1, newItem);
    setCartData(arr);
  };

  const [getActiveCart] = useLazyQuery(GET_ACTIVE_CART, {
    onCompleted: (data) => {
      console.log("On completed of active cart in drawer", data);
      if (data) {
        localStorage.setItem(
          "inova-cart",
          JSON.stringify(data?.activeCart?.cartproductsOfThisCart)
        );
        var arr = [];
        data?.activeCart?.cartproductsOfThisCart?.forEach((item) => {
          arr.push({
            id: parseInt(item?.id),
            qty: item?.qty,
            product: item?.product,
            isRemoved: false,
          });
        });
        setCartData(arr);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [addOrUpdateToCart] = useMutation(ADD_TO_CART, {
    variables: cartUpdateEvent,
    onCompleted: (data) => {
      console.log("Added to cart", data);
      addToCartPage(JSON.parse(localStorage.getItem("inova-customer")).name, "added", cartUpdateEvent.productId, cartUpdateEvent.qty);
      setCartUpdateSuccess((prev) => prev + 1);
      setSnackOpen(true);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  const [removeFromCart] = useMutation(REMOVE_FROM_CART, {
    variables: {
      productId: itemRemovalEvent,
    },
    onCompleted: (data) => {
      console.log("Removed from cart", data);
      addToCartPage(JSON.parse(localStorage.getItem("inova-customer")).name, "removed", itemRemovalEvent, 0);
      setCartRemovalSuccess((prev) => prev + 1);
      setSnackOpen(true);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  useEffect(() => {
    getActiveCart();
  }, [cartUpdateSuccess, cartRemovalSuccess, getActiveCart]);

  useEffect(() => {
    if (cartUpdateEvent.productId !== 0) {
      addOrUpdateToCart();
    }
  }, [cartUpdateEvent, addOrUpdateToCart]);

  useEffect(() => {
    if (itemRemovalEvent !== 0) removeFromCart();
  }, [itemRemovalEvent, removeFromCart]);

  const list = (anchor) => (
    <Box
      className={ classes.boxTempDrawerCart }
      role="presentation"
      onClick={ toggleDrawer(anchor, false) }
      onKeyDown={ toggleDrawer(anchor, false) }
    >
      <Box className={ classes.boxTitleTempDrawerCart }>
        <Typography color="textPrimary" variant="h4">
          My Cart
        </Typography>
      </Box>
      <IconButton
        onClick={ toggleSideBar(false) }
        className={ classes.iconBtnCloseCart }
      >
        <XCircle size={ 30 } />
      </IconButton>
      <Grid container>
        { cartData?.length === 0 ? (
          <Grid item container xs={ 12 } md={ 12 } justifyContent="center">
            <Typography variant="h4" color="primary">
              No Item in Cart
            </Typography>
          </Grid>
        ) : (
          <></>
        ) }
        { cartData?.map((item, idx) => {
          return (
            <Grid
              item
              container
              key={ idx }
              xs={ 12 }
              md={ 12 }
              style={ {
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                borderRadius: "6px",
                margin: "8px 0px",
              } }
            >
              <Grid
                item
                xs={ 12 }
                md={ 4 }
                className={ classes.itemTempDrawerCart }
                style={ {
                  opacity: item?.isRemoved ? 0.3 : 1,
                  position: "relative",
                } }
              >
                <ResizedImage
                  item={ item?.product?.images[0]?.img }
                  alternateText={ item?.product?.name }
                  multiples={ [0.7, 0.08, 0.15, 0.15] }
                  fit={ ImageFit.CONTAIN }
                  bg={ hexToRGB(colors.white1) }
                />
                { item?.product?.discountPercentage ? (
                  <Typography
                    color="textSecondary"
                    className={ classes.textUnitPriceOverlayCartItem }
                  >
                    ৳{ " " }
                    { parseInt(
                      item?.product?.unitPrice -
                      item?.product?.unitPrice *
                      item?.product?.discountPercentage
                    ).toLocaleString() }
                  </Typography>
                ) : (
                  <Typography
                    color="textSecondary"
                    className={ classes.textUnitPriceOverlayCartItem }
                  >
                    ৳ { parseInt(item?.product?.unitPrice).toLocaleString() }
                  </Typography>
                ) }
              </Grid>
              <Grid
                item
                xs={ 12 }
                md={ 4 }
                className={ classes.itemTempDrawerCart }
                style={ {
                  opacity: item?.isRemoved ? 0.3 : 1,
                } }
              >
                <Typography
                  color="textSecondary"
                  style={ { marginBottom: "10px" } }
                >
                  { item?.product?.name }
                </Typography>

                <Box className={ classes.boxItemQuantityUpdateCart }>
                  <IconButton
                    className={ classes.iconBtnPlusMinusCart }
                    onClick={ () => reduceQuantity(item) }
                  >
                    <Minus />
                  </IconButton>
                  <Typography
                    style={ { width: "50px" } }
                    align="center"
                    color="textSecondary"
                  >
                    { item?.qty }
                  </Typography>
                  <IconButton
                    className={ classes.iconBtnPlusMinusCart }
                    onClick={ () => increaseQuantity(item) }
                  >
                    <Plus />
                  </IconButton>
                </Box>
              </Grid>
              <Grid
                item
                xs={ 12 }
                md={ 4 }
                className={ classes.containerItemButtonCart }
              >
                { item.isRemoved ? (
                  <Button
                    color="secondary"
                    variant="contained"
                    style={ { width: "120px" } }
                    startIcon={ <CornerUpLeft /> }
                    onClick={ () => {
                      const arr = cartData.map((obj) =>
                        obj.id === item.id ? { ...obj, isRemoved: false } : obj
                      );
                      setCartData(arr);
                    } }
                  >
                    Undo
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    variant="contained"
                    style={ { width: "120px" } }
                    startIcon={ <Trash2 size={ 20 } /> }
                    onClick={ () => {
                      const arr = cartData.map((obj) =>
                        obj.id === item.id ? { ...obj, isRemoved: true } : obj
                      );
                      setCartData(arr);
                    } }
                  >
                    Remove
                  </Button>
                ) }

                <Button
                  variant="contained"
                  color="secondary"
                  style={ { marginTop: "8px", width: "120px" } }
                  startIcon={ <Check /> }
                  onClick={ () => applyCartChange(item) }
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          );
        }) }

        <Grid item xs={ 12 } md={ 12 } className={ classes.buttonTempDrawerCart }>
          <Button
            variant="contained"
            color="secondary"
            className={ classes.btnCheckoutCart }
            endIcon={ <ShoppingBag size={ 18 } /> }
            onClick={ () => {
              setOpenSideBar(false);
              history.push("/checkout");
            } }
          >
            Proceed To Checkout
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div>
      { isLoggedIn() && !isCartEmpty() ? (
        <IconButton
          onClick={ toggleSideBar(true) }
          className={ classes.btnFloatingCart }
        >
          <Badge badgeContent={ cartData?.length } color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
      ) : (
        <></>
      ) }
      <Drawer
        className={ classes.drawerCart }
        anchor="right"
        open={ openSideBar }
        onClose={ toggleSideBar(false) }
      >
        { list("right") }
      </Drawer>
      { cartUpdateSuccess !== 0 ? (
        <CustomSnackbar
          open={ snackOpen }
          setOpen={ setSnackOpen }
          severity="success"
          message="Cart Updated!"
        />
      ) : (
        // <Typography color="secondary">Cart Updated !</Typography>
        <></>
      ) }
    </div>
  );
};

export default TemporaryDrawer;
