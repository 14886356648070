import * as React from "react";
import { useState } from "react";
import { Menu } from "react-feather";
import {
  Box,
  Drawer,
  IconButton,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";
import {
  LogOut,
  LogIn,
  Heart,
  List as ListIcon,
  //Info
} from "react-feather";
import { isLoggedIn, logOut } from "../../helpers/loginStatus";
const useStyles = makeStyles((theme) => ({
  iconBtnNavbar: {
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    minWidth: "0px",
    padding: "0px 0px 0px 8px",
  },
  sidebarNav: {
    "& .MuiPaper-root": {
      background: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.background.default,
    },
  },
}));
export default function MobileNavDrawer ({ dynamicOptions }) {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openSideBar, setOpenSideBar] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const toggleSideBar = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSideBar(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {isLoggedIn() ? (
          <>
            <ListItem
              button
              key="Profile"
              onClick={() => history.push("/profile")}
            >
              <Avatar style={{ width: "28px", height: "28px" }} />
              <Typography style={{ marginLeft: "8px" }} color="textPrimary">
                {JSON.parse(localStorage.getItem("inova-customer"))?.name}
              </Typography>
            </ListItem>
            <ListItem
              button
              key="Wishlist"
              onClick={() => history.push("/wishlist")}
            >
              <ListItemIcon>
                <Heart />
              </ListItemIcon>
              <ListItemText primary="Wishlist" />
            </ListItem>
          </>
        ) : (
          <></>
        )}
        {/* <ListItem
          button
          key="All Products"
          onClick={() => {
            history.push("/");
            document
              .getElementById("by-categories")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="All Products" />
        </ListItem> */}
        {dynamicOptions?.map((item, idx) => {
          return (
            <ListItem
              button
              key={idx}
              onClick={() => {
                setOpenSideBar(false);
                history.push(`/products?interior=${item.seoName}`);
              }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          );
        })}
        <ListItem
          button
          onClick={() => {
            setOpenSideBar(false);
            history.push(`/designers`);
          }}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary={"Meet Our Designers"} />
        </ListItem>
        {/* <ListItem
          button
          key="About Us"
          onClick={() => {
            setOpenSideBar(false);
            document
              .getElementById("footer")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary="About Us" />
        </ListItem> */}
        {isLoggedIn() ? (
          <ListItem
            button
            key="Sign Out"
            onClick={() => {
              setOpenSideBar(false);
              logOut();
              history.push("/");
            }}
          >
            <ListItemIcon>
              <LogOut />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        ) : (
          <ListItem
            button
            key="Sign In"
            onClick={() => {
              setOpenSideBar(false);
              history.push("/login");
            }}
          >
            <ListItemIcon>
              <LogIn />
            </ListItemIcon>
            <ListItemText primary="Sign In" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton
        onClick={toggleSideBar(true)}
        className={classes.iconBtnNavbar}
      >
        <Menu />
      </IconButton>
      <Drawer
        className={classes.sidebarNav}
        anchor="right"
        open={openSideBar}
        onClose={toggleSideBar(false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
}
