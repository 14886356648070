import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import SignupForm from "./form";
import CommonLayout from "../../layouts/CommonLayout";
import constants from "../../data/constants.json";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "64px 0px 0px 0px",
  },
  textSignIn: {
    fontWeight: "bold",
    marginBottom: "24px",
  },
  boxFirebaseSignup: {
    "& .firebaseui-container": {
      background: theme.palette.background.default,
      borderRadius: "4px",
    },
    "& .firebaseui-title": {
      color: theme.palette.primary.main,
    },
    "& .mdl-button--raised.mdl-button--colored": {
      background: theme.palette.secondary.main,
      color: theme.palette.background.default,
      borderRadius: "4px",
    },
    "& .mdl-button--raised.mdl-button--colored:hover": {
      background: theme.palette.background.default,
      color: theme.palette.secondary.main,
    },
    "& .firebaseui-link": {
      color: theme.palette.secondary.main,
    },
    "& .mdl-button--primary.mdl-button--primary": {
      color: theme.palette.secondary.main,
    },
  },
  signup: {
    fontSize: "20px",
    marginLeft: theme.spacing(1),
    textDecoration: "underline",
  },
  signupText: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    //fontSize: "20px",
  },
}));

const SignupView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [showForm, setShowForm] = useState(false);
  const [token, setToken] = useState();
  const [username, setUsername] = useState();
  const [authMethod, setAuthMethod] = useState();
  var uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: "BD",
        fullLabel: "Sign up with Phone"
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: "Sign up with Google"
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        //console.log(authResult.user.phoneNumber);
        firebase
          .auth()
          .currentUser.getIdToken(false)
          .then(function (idToken) {
            // Send token to your signup mutation
            //console.log(idToken, "found");
            //console.log(authResult.additionalUserInfo.providerId);
            setShowForm(true);
            setToken(idToken);
            setAuthMethod(authResult.additionalUserInfo.providerId);
            if (authResult.additionalUserInfo.providerId === constants.authMethodPhone) {
              setUsername(authResult.user.phoneNumber);
            } else if (authResult.additionalUserInfo.providerId === constants.authMethodGoogle) {
              setUsername(authResult.user.email);
            } else {
              console.log("Invalid Auth Method");
            }
            // setPhoneNumber(authResult.user.phoneNumber); // Username
            // ...
          })
          .catch(function (error) {
            // Handle error
            console.log("callback:", error);
          });
      },
    },
  };
  return (
    <CommonLayout>
      <Container className={classes.root}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            color="secondary"
            className={classes.textSignIn}
          >
            Not a Member?
          </Typography>
          <Typography
            variant="h3"
            color="secondary"
            className={classes.textSignIn}
          >
            Sign Up Now
          </Typography>
          {showForm ? (
            <SignupForm token={token} username={username} authMethod={authMethod} />
          ) : (
            <></>
          )}
          {!showForm ? (
            <StyledFirebaseAuth
              className={classes.boxFirebaseSignup}
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          ) : (
            <></>
          )}
          <div className={classes.signupText}>
            <Typography variant="h4" style={{ marginRight: "16px" }}>Already a Member?</Typography>
            <Button variant="contained" color="primary" onClick={() => history.push('/login')}>
              Sign In
            </Button>
          </div>
          {/* <Button variant="contained" onClick={() => firebase.auth().signOut()}>
            Sign-out
          </Button> */}
        </Grid>
      </Container>
    </CommonLayout>
  );
};

export default SignupView;
