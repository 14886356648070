import firebase from "firebase/compat/app";
import { setUserProps } from "./googleAnalytics";

export function isLoggedIn () {
  if (localStorage.getItem("inova-token")) return true;
  else return false;
}

export function isCartEmpty () {
  if (!isLoggedIn()) return true;
  try {
    if (!localStorage.getItem("inova-cart")) return true;
    if (JSON.parse(localStorage.getItem("inova-cart")).length === 0)
      return true;
  } catch (error) {
    localStorage.removeItem("inova-cart");
    return true;
  }
  return false;
}

export function logOut () {
  firebase.auth().signOut();
  localStorage.removeItem("inova-token");
  localStorage.removeItem("inova-customer");
  localStorage.removeItem("inova-cart");
  setUserProps("");
}
