import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const CustomDialog = (props) => {
  const {
    open,
    handleClose,
    title,
    children,
    positiveButtonText,
    handlePositiveButtonAction,
    negativeButtonText,
    handleNegativeButtonAction,
  } = props;
  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {negativeButtonText && handleNegativeButtonAction ? (
          <Button
            style={{ margin: "1rem 0rem 1rem 1rem" }}
            onClick={handleNegativeButtonAction}
            color="secondary"
            variant="outlined"
          >
            {negativeButtonText}
          </Button>
        ) : (
          <></>
        )}
        <Button
          style={{ margin: "1rem" }}
          onClick={handlePositiveButtonAction}
          color="secondary"
          variant="contained"
        >
          {positiveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
