import { Typography, Grid, Icon, makeStyles } from "@material-ui/core";
import { isLoggedIn } from "../../helpers/loginStatus";
import AddressCard from "../../components/AddressCard";
import { useState } from "react";
import { Plus } from "react-feather";
import AddressCreationForm from "../../components/AddressCreationForm";
import { getDisplayType, DisplayOrientation } from "../../helpers/resizeImage";

const useStyles = makeStyles((theme) => ({
  containerPlusIconInvoiceAddress: {
    marginBottom: "16px",
    padding: "16px",
    height:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? window.innerHeight * 0.3
        : window.innerHeight * 0.16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "8px",
      padding: "8px",
    },
  },
  plusIconInvoiceAddress: {
    width: "40px",
    height: "40px",
    color: theme.palette.secondary.main,
  },
  containerInvoiceAddressTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px 0px 16px",
  },
}));

const InvoiceAddress = ({
  handleAddressSelection,
  setAddressCreateSuccess,
}) => {
  const classes = useStyles();

  const [selectedCardId, setSelectedCardId] = useState();
  const [showAddressCreationForm, setShowAddressCreationForm] = useState(false);

  return (
    <>
      <Grid item xs={12} md={12} className={classes.containerInvoiceAddressTitle}>
        <Typography variant="h4">Select Address</Typography>
      </Grid>
      <Grid container style={{ padding: "16px 0px 0px 16px" }}>
        {isLoggedIn() && localStorage.getItem("inova-customer") ? (
          <>
            {JSON.parse(localStorage.getItem("inova-customer"))?.addresses?.map(
              (item, idx) => {
                return (
                  <Grid
                    item
                    onClick={() => {
                      handleAddressSelection(item);
                      setSelectedCardId(item?.id);
                    }}
                    key={idx}
                    xs={12}
                    md={6}
                    style={{
                      padding: "0px 24px 24px 0px",
                    }}
                  >
                    <AddressCard
                      address={item}
                      isSelected={selectedCardId === item?.id ? true : false}
                    />
                  </Grid>
                );
              }
            )}
          </>
        ) : (
          <></>
        )}
        {showAddressCreationForm ? (
          <AddressCreationForm
            showForm={setShowAddressCreationForm}
            setAddressCreateSuccess={setAddressCreateSuccess}
            showAddressCreationForm={showAddressCreationForm}
            setShowAddressCreationForm={setShowAddressCreationForm}
          />
        ) : (
          <Grid
            item
            xs={12}
            md={6}
            onClick={() => {
              setShowAddressCreationForm(true);
            }}
            style={{ padding: "0px 24px 24px 0px" }}
          >
            <Grid container className={classes.containerPlusIconInvoiceAddress}>
              <Icon className={classes.plusIconInvoiceAddress}>
                <Plus size={40} />
              </Icon>
              <Typography color="secondary" variant="h5">
                Add New Address
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InvoiceAddress;
