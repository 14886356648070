import {
  Box,
  TextField,
  Button,
  //Container,
  Grid,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/client";
import {
  //SIGNUP, 
  SIGNUPV2
} from "../../graphql/Mutations";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useState } from "react";
import { useHistory } from "react-router";
import { signupPage } from "../../helpers/googleAnalytics";
import constants from "../../data/constants.json";
import {
  isTextValid,
  isPhoneNumberValid,
  isEmailValid
} from "../../helpers/validators";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  form: {
    //marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiInputBase-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontSize: "22px",
      paddingLeft: theme.spacing(2),
    },
    "& .MuiFilledInput-input": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      width: 300,
      borderRadius: 4,
      paddingLeft: theme.spacing(3.5),
      fontSize: "20px",
    },
    "& .MuiButton-label": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.4),
      paddingBottom: theme.spacing(0.4),
      fontSize: "18px",
    },
  },
  signupText: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "20px",
  },
}));

const SignupForm = ({ token, username, authMethod }) => {
  const history = useHistory();
  const classes = useStyles();
  //const client = useApolloClient();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  //const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [password, setPassword] = useState();
  // const [confirmPassword, setConfirmPassword] = useState();
  const [newsletterSubscription, setNewsletterSubscription] = useState(false);
  // const [signup, { data }] = useMutation(SIGNUP, {
  //   variables: {
  //     username: phoneNumber,
  //     name: name,
  //     phone: phoneNumber,
  //     password: password,
  //     firebaseIdtoken: token,
  //     newsletterSubscription: newsletterSubscription
  //   },
  //   onCompleted: (data) => {
  //     console.log("On completed of register", data);
  //     if (data) {
  //       const token = JSON.parse(JSON.stringify(data?.signUp?.token));
  //       localStorage.setItem("inova-token", token);
  //       localStorage.setItem(
  //         "inova-customer",
  //         JSON.stringify(data?.signUp?.customer)
  //       );
  //       history.push("/");
  //     }
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //     setShowErrorAlert(true);
  //   },
  // });

  const [signup, { data }] = useMutation(SIGNUPV2, {
    variables: {
      authMethod: authMethod,
      username: username,
      email: authMethod === constants.authMethodGoogle ? username : email,
      name: name,
      phone: authMethod === constants.authMethodPhone ? username : phoneNumber,
      firebaseIdtoken: token,
      newsletterSubscription: newsletterSubscription
    },
    onCompleted: (data) => {
      console.log("On completed of register", data);
      if (data) {
        const token = JSON.parse(JSON.stringify(data?.signUpV2?.token));
        localStorage.setItem("inova-token", token);
        localStorage.setItem(
          "inova-customer",
          JSON.stringify(data?.signUpV2?.customer)
        );
        history.push("/");
      }
    },
    onError: (error) => {
      console.log(error);
      setShowErrorAlert(true);
    },
  });

  const handleSignup = async () => {
    signupPage(name, username)
    setShowErrorAlert(false);

    //setPasswordMismatch(false);
    // if (password !== confirmPassword) {
    //   setPasswordMismatch(true);
    // } else {
    if (!data) await signup();
    //}
  };

  return (
    <>
      <Box className={classes.root}>
        {showErrorAlert ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Signup Failed
            {/* -- <strong>User Already Exists</strong> */}
          </Alert>
        ) : (
          <></>
        )}
        {/* {passwordMismatch ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Signup Failed -- <strong>Passwords Mismatch</strong>
          </Alert>
        ) : (
          <></>
        )} */}
        <form className={classes.form}>
          <TextField
            variant="filled"
            type="text"
            InputLabelProps={{ color: "primary" }}
            id="name"
            label="Name"
            required
            error={!isTextValid(name)}
            helperText={
              !isTextValid(name) ? "This Field is Required" : " "
            }
            onChange={(e) => setName(e.target.value)}
          />
          {authMethod === constants.authMethodPhone ? (
            <TextField
              variant="filled"
              type="text"
              InputLabelProps={{ color: "primary" }}
              id="email"
              label="Email"
              error={!isEmailValid(email)}
              helperText={
                !isEmailValid(email)
                  ? "Enter a valid email address"
                  : " "
              }
              onChange={(e) => setEmail(e.target.value)}
            />
          ) : <></>}
          {authMethod === constants.authMethodGoogle ? (
            <TextField
              variant="filled"
              type="text"
              InputLabelProps={{ color: "primary" }}
              id="phone"
              label="Phone"
              error={!isPhoneNumberValid(phoneNumber)}
              helperText={
                !isPhoneNumberValid(phoneNumber)
                  ? "Enter a valid phone number"
                  : " "
              }
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          ) : <></>}
          {/* <TextField
            variant="filled"
            type="password"
            id="password"
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="filled"
            type="password"
            id="confirmPassword"
            label="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          /> */}
          <Grid container justifyContent="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletterSubscription}
                  onChange={() => setNewsletterSubscription(!newsletterSubscription)}
                  color="primary"
                />
              }
              label="I wish to receive promotional offers and news from INOVA"
            />
          </Grid>
          <Button
            onClick={handleSignup}
            variant="contained"
            color="primary"
            style={{ margin: "16px" }}
          >
            Register
          </Button>
        </form>
      </Box>
    </>
  );
};

export default SignupForm;
