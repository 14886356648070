import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import TemporaryDrawer from "../../components/TemporaryCartDrawer";
import { isLoggedIn, logOut } from "../../helpers/loginStatus";
import { useState, useEffect, useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { VERIFY_TOKEN } from "../../graphql/Mutations";
import { handleAsyncCalls } from "../../helpers/handleAsyncApiCalls";
import { getTimeStampYesterday } from "../../helpers/dateTimeHandlings";
import { logPageView } from "../../helpers/googleAnalytics"
import MessengerCustomerChat from 'react-messenger-customer-chat';

const CommonLayout = ({ children }) => {

  logPageView((window.location.pathname + window.location.search).substr(1));

  const [tokenValidity, setTokenValidity] = useState();
  const client = useApolloClient();

  const verifyToken = useCallback(() => {
    handleAsyncCalls(
      client,
      VERIFY_TOKEN,
      (data) => {
        console.log("ASYNC APOLLO VERIFY TOKEN", data);
        if (data) {
          //console.log("Expiry timestamp", data?.data?.verifyToken?.payload?.exp);
          if (data?.data?.verifyToken?.payload?.exp > getTimeStampYesterday())
            setTokenValidity(true);
          else {
            setTokenValidity(false);
            logOut();
          }
        }
      },
      {
        token: localStorage.getItem("inova-token"),
      }
    );
  }, [client]);

  useEffect(() => {
    if (isLoggedIn()) verifyToken();
  }, [verifyToken, tokenValidity]);

  return (
    <div>
      <Navbar />
      {children}
      {isLoggedIn() ? <TemporaryDrawer /> : <></>}
      <MessengerCustomerChat
        pageId={`${process.env.REACT_APP_FB_PAGE_ID}`}
        appId={`${process.env.REACT_APP_FB_APP_ID}`}
      />
      <Footer />
    </div>
  );
};

export default CommonLayout;
