import { Box, Container, Divider, Grid, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { hexToRGB } from "../../helpers/hexToRGB";
import { ImageFit } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import ResizedImage from "../ResizedImage";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "56px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  containerProduct: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px",
    borderRadius: "6px",
    "&.MuiGrid-spacing-xs-3": {
      width: "inherit",
      margin: "inherit",
    },
  },
}));

const ProductCategories = ({ data }) => {
  const classes = useStyles();
  return (
    <Box
      id="by-categories"
      style={{
        paddingTop: "64px",
      }}
    >
      <Grid container>
        <Grid item xs={12} md={3}>
          <Container className={classes.container}>
            <Typography color="textSecondary" variant="h4">
              Explore all
            </Typography>
            <Typography color="textSecondary" variant="h2">
              Product Categories
            </Typography>
          </Container>
        </Grid>

        {data?.map((item, idx) => {
          return (
            <Grid item key={idx} xs={12} md={3} style={{ padding: "16px" }}>
              <RouterLink to={`/products?category=${item?.seoName}`}>
                <Grid className={classes.containerProduct} container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ padding: "16px 0px 8px 0px" }}
                  >
                    <Typography color="textSecondary" variant="h5" align="center">
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <ResizedImage
                        item={item?.images[0]?.img}
                        alternateText={item.title}
                        multiples={[0.75, 0.175, 0.35, 0.3]}
                        fit={ImageFit.CONTAIN}
                        bg={hexToRGB(colors.white1)}
                      />
                      {/* <img
                        width={
                          getDisplayType() === DisplayOrientation.PORTRAIT
                            ? Math.round(window.innerHeight * 0.2)
                            : Math.round(window.innerHeight * 0.2)
                        }
                        height={
                          getDisplayType() === DisplayOrientation.PORTRAIT
                            ? Math.round(window.innerHeight * 0.2)
                            : Math.round(window.innerHeight * 0.125)
                        }
                        src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
                          item?.images[0]?.img,
                          {
                            resize: {
                              width: 180,
                              height: 100,
                              fit: "contain",
                              background: hexToRGB(colors.white1),
                            },
                          }
                        )}`}
                        alt="by-interior"
                      /> */}
                    </Box>
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%" }} />
              </RouterLink>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProductCategories;
