import {
  Grid,
  Typography,
  Box,
  makeStyles,
  Button
} from "@material-ui/core";
import { Filter as FilterIcon, Minimize2 as Close } from "react-feather";
import CommonLayout from "../../layouts/CommonLayout";
import ProductCard from "../../components/ProductCard";
import { useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import { useQuery } from "@apollo/client";
import { GET_FILTERED_PRODUCTS, GET_ALL_FILTERS } from "../../graphql/Queries";
import Filter from "../../components/Filter";
import { resizeImage } from "../../helpers/resizeImage";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TabPanel from "../../components/TabPanel";
import { Link as RouterLink } from "react-router-dom";
//import constants from "../../data/constants.json";
import { hexToRGB } from "../../helpers/hexToRGB";
import colors from "../../theme/values/colors.json";
import { viewItemListPage } from "../../helpers/googleAnalytics";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  productListTabs: {
    //marginTop: "1.25rem",
    marginBottom: "2rem",
  },
  ContainerProductListFilter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  productListProductWrapper: {
    padding: "16px",
  },
  textProductListTitle: {
    //lineHeight: "45px",
    letterSpacing: "0.02em",
    margin: "2rem 0rem",
  },
  containerProductListUpper: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  containerProductListLower: {
    //marginTop: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconBtnProductListFilters: {
    margin: "0px 16px",
    color: theme.palette.background.default,
    "&.MuiIconButton-root:hover": {
      background: colors.grey0,
    },
  },
  imageProductListCarousel: {
    [theme.breakpoints.down("md")]: {
      height: `${Math.round(`${window.innerHeight}` * 0.65)}`,
    },
  },
}));

const ProductListView = () => {

  viewItemListPage();
  const classes = useStyles();
  const { space, interior, category, material, searchQuery, priceLow, priceHigh } =
    useQueryParams();
  const [carouselImages, setCarouselImages] = useState([]);
  const [openFilterPanel, setOpenFilterPanel] = useState();
  const [pageTitle, setPageTitle] = useState("Filtered Products");
  //const [filters, setFilters] = useState([]);
  const setImagesForCarousel = (filters) => {
    const carImages = [];
    if (filters?.space) {
      carImages.push(filters?.space?.images[0]?.img);
    }
    if (filters?.interior) {
      carImages.push(filters?.interior?.images[0]?.img);
    }
    if (filters?.category) {
      carImages.push(filters?.category?.images[0]?.img);
    }
    setCarouselImages(carImages);
  };
  const setTitle = (filters) => {
    if (filters?.space && !filters?.interior && !filters?.category) {
      setPageTitle(filters?.space?.name);
    } else if (!filters?.space && filters?.interior && !filters?.category) {
      setPageTitle(filters?.interior?.name);
    } else if (!filters?.space && !filters?.interior && filters?.category) {
      setPageTitle(filters?.category?.name);
    } else setPageTitle(`Filtered Products`);
  };

  const { data, error } = useQuery(GET_FILTERED_PRODUCTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      filters: {
        spaceSeoName: space,
        interiorSeoName: interior,
        categorySeoName: category,
        materialSeoName: material,
        searchQuery: searchQuery,
        priceLow: parseInt(priceLow),
        priceHigh: parseInt(priceHigh),
      },
    },
    onCompleted: (data) => {
      console.log("on completed of product list", data);
      setOpenFilterPanel(false);
      const filters = data?.filterProducts?.selectedFilters;
      setImagesForCarousel(filters);
      setTitle(filters);
    },
    onError: () => {
      console.log(error);
    },
  });

  const { data: allFilters, error: allFiltersError } = useQuery(
    GET_ALL_FILTERS,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted: (allFilters) => {
        console.log(
          "on completed of get all filters in product list",
          allFilters
        );
      },
      onError: () => {
        console.log(allFiltersError);
      },
    }
  );

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // const addItemsInFilter = (particularTypeOfFilter) => {
  //   for (
  //     let i = 0;
  //     i < particularTypeOfFilter?.length;
  //     i = i + constants.filterMinSize
  //   ) {
  //     setFilters((prev) => [
  //       ...prev,
  //       particularTypeOfFilter?.slice(i, i + constants.filterMinSize),
  //     ]);
  //   }
  // };

  // useEffect(() => {
  //   addItemsInFilter(allFilters?.allSpaces);
  //   addItemsInFilter(allFilters?.allInteriors);
  //   addItemsInFilter(allFilters?.categoryGraph);
  //   //console.log(filters);
  // }, [allFilters]);
  //console.log(filters);
  const titleDescription = "Filtered by " + window.location.href.split("/")[3].split("?").join(" ").split("=").join(" ").split("&").join(",");

  return (
    <CommonLayout>
      <Helmet>
        <title>{titleDescription}</title>
        <meta name="description" content="Checkout this product list of Inova Furniture Ltd ! " />
        <meta name="title" content={data?.productDetail?.name} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={titleDescription} />
        <meta property="og:description" content="Checkout this product list of Inova Furniture Ltd ! " />
        <meta property="og:image" content={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(data?.filterProducts?.products[0]?.images[0]?.img, {})}`} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={titleDescription} />
        <meta property="twitter:description" content="Checkout this product list of Inova Furniture Ltd ! " />
        <meta property="twitter:image" content={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(data?.filterProducts?.products[0]?.images[0]?.img, {})}`} />
      </Helmet>
      {!data ? (
        <></>
      ) : (
        <Box>
          <Grid container className={classes.containerProductListUpper}>
            <Grid item xs={12} md={12}>
              <Carousel
                showThumbs={false}
                showStatus={false}
                dynamicHeight={false}
                stopOnHover
                swipeable
                showIndicators={false}
                infiniteLoop
                autoPlay
                showArrows={false}
              >
                {carouselImages.map((item, idx) => {
                  return (
                    <img
                      key={idx}
                      width="100%"
                      height={Math.round(window.innerHeight * 0.65)}
                      className={classes.imageProductListCarousel}
                      src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
                        item,
                        {
                          resize: {
                            width: Math.round(window.innerWidth),
                            height: Math.round(window.innerHeight * 0.65),
                            fit: "contain",
                            background: hexToRGB(colors.white1),
                          },
                        }
                      )}`}
                      alt="space-interior-category"
                    />
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
          <Grid className={classes.containerProductListLower} container>
            <Grid item>
              <Typography
                color="textSecondary"
                variant="h3"
                className={classes.textProductListTitle}
              >
                {pageTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.iconBtnProductListFilters}
                color="primary"
                variant="contained"
                //style={ { width: "120px" } }
                startIcon={!openFilterPanel ? <FilterIcon size={16} /> : <Close size={16} />}
                onClick={() => setOpenFilterPanel(!openFilterPanel)}
              >
                Filters
              </Button>
            </Grid>
            {/* <Grid item>
              <IconButton
                onClick={() => setOpenFilterPanel(!openFilterPanel)}
                className={classes.iconBtnProductListFilters}
              >
                {!openFilterPanel ? <FilterIcon /> : <Close />}
              </IconButton>
            </Grid> */}
            {openFilterPanel ? (
              <Grid
                item
                xs={12}
                md={12}
                className={classes.ContainerProductListFilter}
              >
                {allFilters ? (
                  <Filter allFilters={allFilters} appliedFilters={space} />
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <></>
            )}
            {data?.filterProducts?.selectedFilters?.category &&
              data?.filterProducts?.selectedFilters?.category?.subcategories
                .length !== 0 ? (
              <Grid item xs={12} md={12} className={classes.productListTabs}>
                <TabPanel
                  items={
                    data?.filterProducts?.selectedFilters?.category
                      ?.subcategories
                  }
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} md={12}>
              <Grid container>
                {data?.filterProducts?.products?.length === 0 ? (
                  <Grid item container xs={12} md={12} justifyContent="center">
                    <Typography variant="h4" color="primary">
                      No Product to Show
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {data?.filterProducts?.products?.map((item, idx) => {
                  return (
                    <Grid
                      item
                      key={idx}
                      xs={12}
                      md={4}
                      className={classes.productListProductWrapper}
                    >
                      <RouterLink to={`/product/${item?.seoName}`}>
                        <ProductCard
                          imageLink={item?.images[0]?.img}
                          name={item?.name}
                          price={item?.unitPrice}
                        />
                      </RouterLink>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </CommonLayout>
  );
};

export default ProductListView;
