import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import { hexToRGB } from "../../helpers/hexToRGB";
import { ImageFit } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import ResizedImage from "../ResizedImage";
const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: "16px",
  },
  textProductCardTitle: {
    padding: "4px 0px",
  },
}));
const ProductCard = ({ imageLink, name, price }) => {
  const classes = useStyles();
  return (
    <Box className={classes.outerContainer}>
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "6px",
        }}
      >
        <Grid item xs={12} md={12}>
          <Box>
            <ResizedImage
              item={imageLink}
              alternateText={name}
              multiples={[0.7, 0.175, 0.15, 0.2]}
              fit={ImageFit.CONTAIN}
              bg={hexToRGB(colors.white1)}
            />
            {/* <img
              src={`${process.env.REACT_APP_CLOUD_FRONT}${resizeImage(
                imageLink,
                {
                  resize: {
                    width: 280,
                    height: 150,
                    fit: "contain",
                    background: hexToRGB(colors.white1),
                  },
                }
              )}`}
              alt={name}
              width="280px"
              height="150px"
            /> */}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            gutterBottom
            color="textSecondary"
            variant="h5"
            align="center"
            className={classes.textProductCardTitle}
          >
            {name}
          </Typography>
          {price < 1 ? (
            <Typography gutterBottom color="textSecondary" variant="h5">
              Contact For Price
            </Typography>
          ) : (
            <Typography gutterBottom color="textSecondary" variant="h5">
              {price} BDT
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductCard;
