import { gql } from "@apollo/client";

export const GET_HOME_FEED = gql`
  query getHomeFeed {
    activeHeroBanners {
      seoTitle
      title
      subtitle
      meta
      bgColorHex
      relatedProduct {
        seoName
      }
      relatedCategory {
        seoName
      }
      relatedUrl
      images {
        img
      }
    }
    allSpaces {
      seoName
      id
      name
      images {
        img
      }
    }
    allInteriors {
      seoName
      name
      images {
        img
      }
    }
    categoryGraph {
      seoName
      name
      images {
        img
      }
    }
    allDesigners {
      id
      education
      profession
      customerProfile {
        name
        photo
      }
    }
  }
`;

export const GET_FILTERED_PRODUCTS = gql`
  query getFilteredProducts($filters: ProductFilterInputType) {
    filterProducts(filters: $filters) {
      products {
        seoName
        name
        description
        unitPrice
        images {
          img
        }
      }
      selectedFilters {
        space {
          name
          images {
            img
          }
        }
        interior {
          name
          images {
            img
          }
        }
        category {
          name
          images {
            img
          }
          subcategories {
            name
            seoName
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetails($seoName: String) {
    productDetail(seoName: $seoName) {
      name
      id
      description
      unitPrice
      stockCount
      discountPercentage
      designedBy {
        seoName
        customerProfile {
          name
          photo
        }
      }
      length {
        value
        unit
      }
      width {
        value
        unit
      }
      height {
        value
        unit
      }
      images {
        img
      }
      materials {
        name
        description
        images {
          img
        }
      }
      categories {
        seoName
      }
      spaces {
        seoName
      }
      interiors {
        seoName
      }
      reviews {
        rating
        review
        user {
          name
        }
      }
    }
  }
`;

export const GET_ALL_FILTERS = gql`
  query getAllFilters {
    allSpaces {
      name
      seoName
    }
    allInteriors {
      name
      seoName
    }
    categoryGraph {
      name
      seoName
    }
  }
`;

export const GET_ACTIVE_CART = gql`
  query getActiveCart {
    activeCart {
      cartproductsOfThisCart {
        product {
          id
          seoName
          name
          unitPrice
          images {
            img
          }
          discountPercentage
        }
        id
        qty
      }
    }
  }
`;

export const GET_ORDER_INVOICE = gql`
  query getOrderInvoice {
    orderInvoice {
      totalAmount
      totalAmountWithoutVat
      totalVat
      vatPercentage
      appliedCouponName
      couponDiscountAmount
      orderedProducts {
        totalAmountWithDiscount
        totalDiscountAmount
        product {
          name
          unitPrice
          images {
            img
          }
          discountPercentage
        }
        qty
      }
    }
  }
`;

export const GET_CUSTOMER_PROFILE = gql`
  query getCustomerProfile {
    customer {
      name
      phone
      referralCode
      points
      user {
        username
        password
      }
      addresses {
        id
        addressLine
        city {
          name
        }
        zipCode
        phone
        label
      }
      wishlistproductsOfThisCustomer {
        product {
          id
          seoName
          name
          unitPrice
          images {
            img
          }
        }
      }
    }
  }
`;

export const GET_ORDER_HISTORY = gql`
  query orderHistory {
    orderHistory {
      id
      status {
        code
      }
      paymentReceived
      referralCode
      shippingAddress {
        label
      }
      invoice {
        orderedProducts {
          product {
            name
          }
        }
      }
    }
  }
`;

export const GET_ALL_CITIES = gql`
  query allCities {
    allCities {
      name
      id
    }
  }
`;
export const GET_ALL_DESIGNERS = gql`
  query allDesigners {
    allDesigners {
      id
      seoName
      education
      profession
      customerProfile {
        name
        photo
      }
    }
  }
`;
export const GET_ALL_SERVICES = gql`
  query{
    allServices{
      title,
      imageIcon
    }
  }
`;

export const GET_ALL_PACKAGES = gql`
  query{
    allPackages{
      title,
      service{
        imageIcon,
        title
      },
      price
    }
  }
`;

export const GET_DESIGNER_DETAILS = gql`
query getDesignerDetails($seoName: String!) {
  designerDetail(seoName: $seoName) {
    seoName
    profession
    education
    aboutMe
    designedProducts {
      seoName
      name
      images {
        img
      }
    }
    customerProfile {
      photo
      name
      
    }
  }
}

`;

export const GET_ALL_WORK_PROCESS = gql`
query{
  allWorkProcesses{
    description,
    position
  }
}
`;