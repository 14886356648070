import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/index";
import './setupFirebase';
import './setupSentry';

ReactDOM.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

