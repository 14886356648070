import { Typography, Grid, Icon, makeStyles } from "@material-ui/core";
import { CheckSquare } from "react-feather";
import { getDisplayType, DisplayOrientation } from "../../helpers/resizeImage";
const useStyles = makeStyles((theme) => ({
  containerAddressCard: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    borderRadius: "6px",
    textAlign: "center",
    height:
      getDisplayType() === DisplayOrientation.PORTRAIT
        ? window.innerHeight * 0.3
        : window.innerHeight * 0.16,
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  iconSelectCard: {
    position: "absolute",
    top: 10,
    left: 10,
    width: "30px",
    height: "30px",
    color: theme.palette.secondary.main,
  },
}));
const AddressCard = ({ address, isSelected }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.containerAddressCard}>
      {isSelected ? (
        <Icon className={classes.iconSelectCard}>
          <CheckSquare size={30} />
        </Icon>
      ) : (
        <></>
      )}
      <Typography variant="h5" color="secondary">
        {address?.label}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {address?.addressLine.length >= 35
          ? `${address?.addressLine.slice(0, 35)}....`
          : address?.addressLine}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {address?.city?.name} - {address?.zipCode}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {address?.phone}
      </Typography>
    </Grid>
  );
};

export default AddressCard;
