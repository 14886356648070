import {
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import RangeSlider from "../RangeSlider";
import { useHistory } from "react-router";
import constants from "../../data/constants.json";
const useStyles = makeStyles((theme) => ({
  containerFilterOuter: {
    padding: "0px 0px 16px 0px",
    background: theme.palette.background.default,
    borderRadius: "4px",
    //width: "fit-content",
    display: "flex",
    justifyContent: "center",
  },
  containerFilterItems: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 0px 16px",
    marginBottom: "2rem"
  },
  containerFilterBtn: {
    marginBottom: "2rem"
  },
  btnFilter: {
    "&.MuiButton-root": {
      minWidth: "82px",
      minHeight: "46px",
    },
  },
  textFilterHeading: {
    fontWeight: "bold",
    //textAlign: "center",
    marginBottom: "1rem",
  },
  radioGroup: {
    flex: 1,
    flexWrap: "wrap",
  },
  radio: {
    "&$checked": {
      color: theme.palette.info.main,
    },
  },
  checked: {},
  priceRangeShowingForm: {
    marginTop: "16px",
    width: "80%",
    "& .MuiOutlinedInput-root": {
      background: theme.palette.background.default,
    },
    "& .MuiOutlinedInput-input": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main
    },
  },
  searchQueryShowingForm: {
    "& .MuiOutlinedInput-root": {
      background: theme.palette.background.default,
    },
    "& .MuiOutlinedInput-input": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
    "& input::placeholder": {
      fontSize: "12px"
    }
  }
}));

const Filter = ({ allFilters }) => {
  const classes = useStyles();
  const history = useHistory();
  const [priceRange, setPriceRange] = useState([constants.priceLow, constants.priceHigh]);
  const [filter, setFilter] = useState({
    space: "",
    interior: "",
    category: "",
    searchQuery: "",
    priceLow: constants.priceLow,
    priceHigh: constants.priceHigh,
  });

  const handleChangePriceRange = (event, newValue) => {
    setPriceRange(newValue);
    setFilter((prevState) => ({
      ...prevState,
      priceLow: priceRange[0],
      priceHigh: priceRange[1],
    }));
  };

  const handleClickSpace = (e) => {
    if (e.target.value === filter.space) {
      setFilter((prevState) => ({
        ...prevState,
        space: "",
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        space: e.target.value,
      }));
    }
  };

  const handleClickInterior = (e) => {
    if (e.target.value === filter.interior) {
      setFilter((prevState) => ({
        ...prevState,
        interior: "",
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        interior: e.target.value,
      }));
    }
  };

  const handleClickCategory = (e) => {
    if (e.target.value === filter.category) {
      setFilter((prevState) => ({
        ...prevState,
        category: "",
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        category: e.target.value,
      }));
    }
  };
  return (
    <Grid container className={classes.containerFilterOuter}>
      <Grid item xs={6} md={2} className={classes.containerFilterItems}>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.textFilterHeading}
        >
          Category
        </Typography>
        {/* <Grid container spacing={3}>
          {(() => {
            let allCats = [];
            for (let i = 0; i < allFilters.categoryGraph?.length; i = i + 4) {
              allCats.push(
                <Grid item xs={6} md={6} key={i}>
                  <RadioGroup
                    className={classes.radioGroup}
                    aria-label="category"
                    name="category"
                    value={filter.category}
                  >
                    {allFilters.categoryGraph
                      ?.slice(i, i + 4)
                      .map((item, idx) => {
                        return (
                          <FormControlLabel
                            key={idx}
                            value={item.seoName}
                            control={
                              <Radio
                                onClick={(e) => handleClickCategory(e)}
                                disableRipple
                                classes={{
                                  root: classes.radio,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label={item.name}
                          />
                        );
                      })}
                  </RadioGroup>
                </Grid>
              );
            }
            return allCats;
          })()}
        </Grid> */}
        <RadioGroup
          className={classes.radioGroup}
          aria-label="category"
          name="category"
          value={filter.category}
        >
          {allFilters.categoryGraph?.map((item, idx) => {
            return (
              <FormControlLabel
                key={idx}
                value={item.seoName}
                control={
                  <Radio
                    onClick={(e) => handleClickCategory(e)}
                    disableRipple
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label={item.name}
              />
            );
          })}
        </RadioGroup>
      </Grid>
      <Grid item xs={6} md={2} className={classes.containerFilterItems}>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.textFilterHeading}
        >
          Space
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          aria-label="space"
          name="space"
          value={filter.space}
        >
          {allFilters.allSpaces?.map((item, idx) => {
            return (
              <FormControlLabel
                key={idx}
                value={item.seoName}
                control={
                  <Radio
                    onClick={(e) => handleClickSpace(e)}
                    disableRipple
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={item.name}
              />
            );
          })}
        </RadioGroup>
      </Grid>
      <Grid item xs={6} md={2} className={classes.containerFilterItems}>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.textFilterHeading}
        >
          Interior
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          aria-label="interior"
          name="interior"
          value={filter.interior}
        >
          {allFilters.allInteriors?.map((item, idx) => {
            return (
              <FormControlLabel
                key={idx}
                value={item.seoName}
                control={
                  <Radio
                    onClick={(e) => handleClickInterior(e)}
                    disableRipple
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={item.name}
              />
            );
          })}
        </RadioGroup>
      </Grid>
      
      <Grid item xs={12} md={2} className={classes.containerFilterItems}>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.textFilterHeading}
        >
          Price Range
        </Typography>
        <RangeSlider value={priceRange} handleChange={handleChangePriceRange} />
        <Grid container style={{ marginBottom: "32px" }}>
          <Grid item xs={6} md={6}>
            <form className={classes.priceRangeShowingForm}>
              <TextField
                variant="outlined"
                type="number"
                value={filter?.priceLow}
                onChange={(e) => {
                  setPriceRange([e.target.value, filter?.priceHigh]);
                  setFilter((prevState) => ({
                    ...prevState,
                    priceLow: e.target.value,
                  }));
                }}
              />
            </form>
          </Grid>
          <Grid item xs={6} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <form className={classes.priceRangeShowingForm}>
              <TextField
                variant="outlined"
                type="number"
                value={filter?.priceHigh}
                onChange={(e) => {
                  setPriceRange([filter?.priceLow, e.target.value]);
                  setFilter((prevState) => ({
                    ...prevState,
                    priceHigh: e.target.value,
                  }));
                }}
              />
            </form>
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.textFilterHeading}
        >
          Search Query
        </Typography>
        <form className={classes.searchQueryShowingForm}>
          <TextField
            variant="outlined"
            type="text"
            value={filter?.searchQuery}
            placeholder="Search for products, categories, materials..."
            onChange={(e) => {
              setFilter((prevState) => ({
                ...prevState,
                searchQuery: e.target.value,
              }));
            }}
          />
        </form>
      </Grid>
      <Grid item xs={12} md={12} display="flex" align="center" className={classes.containerFilterBtn}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnFilter}
          onClick={() => {
            history.push(
              `/products?space=${filter.space}&interior=${filter.interior}&category=${filter.category}&searchQuery=${filter.searchQuery}&priceLow=${filter.priceLow}&priceHigh=${filter.priceHigh}`
            );
          }}
        >
          Filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default Filter;
