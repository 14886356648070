export const DisplayOrientation = {
  PORTRAIT: 0,
  LANDSCAPE: 1,
};

export const ImageFit = {
  CONTAIN: "contain",
  INSIDE: "inside",
  COVER: "cover",
};

export function resizeImage(imageLink, imageEdits) {
  const customEdits = {
    ...imageEdits,
    rotate: null,
  };
  const body = {
    bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    key: imageLink,
    edits: customEdits,
  };
  const encodedImage = btoa(JSON.stringify(body));
  //console.log(imageEdits);
  return imageEdits ? encodedImage : imageLink;
}

export function getDisplayType() {
  if (window.innerHeight > window.innerWidth)
    return DisplayOrientation.PORTRAIT;
  else return DisplayOrientation.LANDSCAPE;
}
