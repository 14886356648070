import ResizedImage from "../ResizedImage";
import { hexToRGB } from "../../helpers/hexToRGB";
import { ImageFit } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import { Button, Box, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  extraBannerButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }
}));

const ExtraBanner = ({ item }) => {
  const classes = useStyles();
  const CustomRouterLink = ({ item, children }) => {
    return (
      <>
        {
          item?.relatedUrl ? (
            <a href={item?.relatedUrl} target="_blank" rel="noreferrer">{children}</a>
          ) : (
            <RouterLink
              to={
                item.relatedProduct
                  ? `/product/${item.relatedProduct.seoName}`
                  : item.relatedCategory
                    ? `/products?category=${item.relatedCategory.seoName}`
                    : `/`
              }
            >
              {children}
            </RouterLink>
          )
        }
      </>
    )
  }



  return (
    <CustomRouterLink item={item}>
      <Box style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <ResizedImage
          item={item?.images[0]?.img}
          alternateText="Extra Banner"
          multiples={[1, 1, 1, 1]}
          fit={ImageFit.INSIDE}
          bg={hexToRGB(colors.white1)}
        />
        <Button className={classes.extraBannerButton} size="large" variant="contained" color="secondary" style={{
          position: "absolute",
          fontSize: "20px",
          padding: "16px",
          opacity: 0.5
        }}>{item?.title}</Button>
      </Box>
    </CustomRouterLink>
  );
};

export default ExtraBanner;
