import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { ShoppingCart, Home, Check } from "react-feather";
import colors from "../../theme/values/colors.json";

function getSteps() {
  return ["Check Items Cart", "Select Address", "Confirm Order"];
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: colors.gradient0,
    },
  },
  completed: {
    "& $line": {
      backgroundImage: colors.gradient0,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: colors.grey0,
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: colors.grey1,
    zIndex: 1,
    color: colors.white1,
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: colors.gradient0,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: colors.gradient0,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <ShoppingCart />,
    2: <Home />,
    3: <Check />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const CustomStepper = ({ activeStep }) => {
  const steps = getSteps();
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
