import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router";
import {
  useLazyQuery,
  useQuery,
  useMutation,
  useApolloClient,
} from "@apollo/client";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";

import CommonLayout from "../../layouts/CommonLayout";
import {
  Grid,
  Typography,
  Divider,
  Button,
  makeStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import ItemCard from "../../components/ItemCard";
import { ShoppingCart as CartIcon, Heart as WishlistIcon } from "react-feather";

import {
  GET_PRODUCT_DETAILS,
  GET_ACTIVE_CART,
  GET_CUSTOMER_PROFILE,
  GET_FILTERED_PRODUCTS,
} from "../../graphql/Queries";
import {
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  ADD_REVIEW,
} from "../../graphql/Mutations";
import { hexToRGB } from "../../helpers/hexToRGB";
import { ImageFit, resizeImage } from "../../helpers/resizeImage";
import colors from "../../theme/values/colors.json";
import CarouselWithResizedImage from "../../components/CarouselWithResizedImage";
import CustomSnackbar from "../../components/CustomSnackbar";
import CustomDialog from "../../components/CustomDialog";
import ResizedImage from "../../components/ResizedImage";
import { isLoggedIn } from "../../helpers/loginStatus";
import { handleAsyncCalls } from "../../helpers/handleAsyncApiCalls";
import ReviewCard from "../../components/ReviewCard";
import {
  addToCartPage,
  addToWishlistPage,
  viewItemPage,
} from "../../helpers/googleAnalytics";
import { Rating } from "@material-ui/lab";
import CustomFormLargeWidth from "../../components/CustomFormLargeWidth";
const useStyles = makeStyles((theme) => ({
  dividerProductDetails: {
    width: "100%",
    backgroundColor: colors.grey1,
    margin: "16px 0px",
  },
  textProductDetailsTitle: {
    fontWeight: 300,
    lineHeight: "30px",
    letterSpacing: "0.02em",
    margin: "16px 0px",
  },
  textProductDetailsSubtitle: {
    fontWeight: 200,
    lineHeight: "24px",
    letterSpacing: "0.02em",
    margin: "16px 0px",
  },
  textProductDetailsBody: {
    fontWeight: "normal",
    marginBottom: "16px",
  },
  textProductDetailsPrice: {
    fontWeight: "bold",
    lineHeight: "27px",
    letterSpacing: "0.02em",
    marginLeft: "8px",
    fontStyle: "italic",
    color: theme.palette.secondary.main,
    fontSize: "2rem",
  },
  textProductDetailsMaterialName: {
    fontWeight: "bold",
    marginBottom: "4px",
  },
  textProductDetailsMaterialDetails: {
    fontSize: "0.9rem",
  },
  containerProductDetailsRight: {
    padding: "0px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    //boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 16px",
    },
  },
  containerProductDetailsLeft: {
    padding: "0px 0px 0px 24px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 16px",
    },
  },
  containerCenterJustified: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  btnProductDetailsBuy: {
    borderRadius: "2rem",
    minWidth: "10rem",
    minHeight: "3rem",
    "&.MuiButton-root:hover": {
      background: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.5rem 1.5rem",
      minWidth: "0rem",
      minHeight: "0rem",
    },
  },
  iconBtnProductDetails: {
    minHeight: "2rem",
    minWidth: "2rem",
    margin: "2px 0px 0px 0px",
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    "&.MuiIconButton-root:hover": {
      background: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
      minHeight: "auto",
    },
  },
  productReviewMisc: {
    padding: "16px 24px 0px 24px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 16px 0px 16px",
    },
  },
}));

const ProductDetailsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { seoName } = useParams();
  const client = useApolloClient();
  const [cartItemAddEvent, setCartItemAddEvent] = useState({
    productId: 0,
    qty: 0,
  });
  const [cartAddSuccess, setCartAddSuccess] = useState(0);
  const [cartAddFailForInvalidPrice, setCartAddFailForInvalidPrice] =
    useState(false);
  const [cartSuccessSnackOpen, setCartSuccessSnackOpen] = useState(false);
  const [showLoginRedirection, setShowLoginRedirection] = useState(false);

  const [wishlistAddSuccess, setWishlistAddSuccess] = useState(false);
  const [wishlistItemAddEvent, setWishlistItemAddEvent] = useState(-1);
  const [wishlistSuccessSnackOpen, setWishlistSuccessSnackOpen] =
    useState(false);

  const [productReview, setProductReview] = useState();
  const [productRating, setProductRating] = useState(0);
  const [reviewSuccessSnackOpen, setReviewSuccessSnackOpen] = useState(false);

  const [similarProductsFilter, setSimilarProductsFilter] = useState({
    spaceSeoName: "",
    interiorSeoName: "",
    categorySeoName: "",
  });

  const { data, error } = useQuery(GET_PRODUCT_DETAILS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      seoName: seoName,
    },
    onCompleted: (data) => {
      console.log("on completed of product details", data);
      viewItemPage(data.productDetail.id, seoName);
      setSimilarProductsFilter({
        spaceSeoName: data?.productDetail?.spaces[0]?.seoName || "",
        interiorSeoName: data?.productDetail?.interiors[0]?.seoName || "",
        categorySeoName: data?.productDetail?.categories[0]?.seoName || "",
      });
    },
    onError: () => {
      console.log(error);
    },
  });

  const [getSimilarProducts, { data: similarProductsData }] = useLazyQuery(
    GET_FILTERED_PRODUCTS,
    {
      variables: {
        filters: similarProductsFilter,
      },
      onCompleted: (data) => {
        console.log("on completed of filtering similar products", data);
      },
      onError: () => {
        console.log(error);
      },
    }
  );

  const [getActiveCart] = useLazyQuery(GET_ACTIVE_CART, {
    onCompleted: (data) => {
      //setCartData(data?.activeCart?.cartproductsOfThisCart);
      console.log("On completed of active cart in product details", data);
      if (data) {
        localStorage.setItem(
          "inova-cart",
          JSON.stringify(data?.activeCart?.cartproductsOfThisCart)
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [addOrUpdateToCart] = useMutation(ADD_TO_CART, {
    variables: cartItemAddEvent,
    onCompleted: (data) => {
      console.log("Added to cart", data);
      addToCartPage(
        JSON.parse(localStorage.getItem("inova-customer")).name,
        "added",
        cartItemAddEvent.productId
      );
      setCartAddSuccess((prev) => prev + 1);
      setCartSuccessSnackOpen(true);
    },
    onError: (error) => {
      console.log("Error in adding to wishlist", error);
    },
  });

  const [addReview] = useMutation(ADD_REVIEW, {
    variables: {
      productId: data?.productDetail?.id,
      rating: productRating,
      review: productReview,
    },
    onCompleted: (data) => {
      console.log("On completed of add review", data);
      setReviewSuccessSnackOpen(true);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  const [addToWishlist] = useMutation(ADD_TO_WISHLIST, {
    variables: {
      productId: wishlistItemAddEvent,
    },
    onCompleted: (data) => {
      console.log("Added to wishlist", data);
      addToWishlistPage(
        JSON.parse(localStorage.getItem("inova-customer")).name,
        "added",
        wishlistItemAddEvent
      );
      setWishlistAddSuccess(true);
      setWishlistSuccessSnackOpen(true);
    },
    onError: (error) => {
      console.log("Error in adding to wishlist", error);
    },
  });

  const getCustomerProfile = useCallback(() => {
    handleAsyncCalls(client, GET_CUSTOMER_PROFILE, (data) => {
      console.log("ASYNC APOLLO CUSTOMER PROFILE", data);
      if (data) {
        localStorage.setItem(
          "inova-customer",
          JSON.stringify(data?.data?.customer)
        );
      }
    });
  }, [client]);

  useEffect(() => {
    if (cartItemAddEvent.productId !== 0) {
      addOrUpdateToCart();
    }
  }, [cartItemAddEvent, addOrUpdateToCart]);

  useEffect(() => {
    getActiveCart();
  }, [cartAddSuccess, getActiveCart]);

  useEffect(() => {
    if (wishlistItemAddEvent !== -1) {
      addToWishlist();
    }
  }, [wishlistItemAddEvent, addToWishlist]);

  useEffect(() => {
    if (wishlistAddSuccess) getCustomerProfile();
  }, [wishlistAddSuccess, getCustomerProfile]);
  //console.log("data", data);
  //console.log(window.location.href);
  useEffect(() => {
    getSimilarProducts();
  }, [getSimilarProducts, similarProductsFilter]);
  return (
    <>
      <Helmet>
        <title>{data?.productDetail?.name}</title>

        <meta name="description" content={data?.productDetail?.description} />
        <meta name="title" content={data?.productDetail?.name} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={data?.productDetail?.name} />
        <meta property="og:description" content={data?.productDetail?.description} />
        <meta property="og:image" content={process.env.REACT_APP_CLOUD_FRONT + resizeImage(data?.productDetail?.images[0]?.img, {})} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={data?.productDetail?.name} />
        <meta property="twitter:description" content={data?.productDetail?.description} />
        <meta property="twitter:image" content={process.env.REACT_APP_CLOUD_FRONT + resizeImage(data?.productDetail?.images[0]?.img, {})} />
      </Helmet>

      <CommonLayout>
        {data ? (
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              className={classes.containerProductDetailsLeft}
            >
              <Grid
                container
                display="flex"
                direction="column"
                justifyContent="flex-start"
              >
                <Grid item xs={12} md={12}>
                  <CarouselWithResizedImage
                    imageList={data?.productDetail?.images}
                    alternateText="product-details"
                    multiples={[0.95, 0.65, 0.45, 0.95]}
                    fit={ImageFit.CONTAIN}
                    bg={hexToRGB(colors.white1)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    className={classes.textProductDetailsSubtitle}
                  >
                    Description
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    className={classes.textProductDetailsBody}
                  >
                    {data?.productDetail?.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.containerProductDetailsRight}
            >
              <Divider className={classes.dividerProductDetails} />
              <Typography
                color="textSecondary"
                variant="h3"
                className={classes.textProductDetailsTitle}
              >
                {data?.productDetail?.name}
              </Typography>
              {data?.productDetail?.stockCount === 0 ? (
                <></>
              ) : (
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className={classes.textProductDetailsBody}
                >
                  IN STOCK
                </Typography>
              )}
              {data?.productDetail?.unitPrice < 1 ? (
                <Typography
                  color="primary"
                  variant="h5"
                  style={{ marginBottom: "16px" }}
                >
                  Contact For Price
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  variant="h4"
                  style={{ marginBottom: "16px" }}
                >
                  <span>Price: BDT</span>
                  {data?.productDetail?.discountPercentage ? (
                    <>
                      <span
                        className={classes.textProductDetailsPrice}
                        style={{
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                        }}
                      >
                        {data?.productDetail?.unitPrice}/-
                      </span>
                      <span className={classes.textProductDetailsPrice}>
                        {Math.round(
                          data?.productDetail?.unitPrice -
                          data?.productDetail?.unitPrice *
                          data?.productDetail?.discountPercentage
                        )}
                        /-
                      </span>
                    </>
                  ) : (
                    <span className={classes.textProductDetailsPrice}>
                      {data?.productDetail?.unitPrice}/-
                    </span>
                  )}
                </Typography>
              )}

              <Divider className={classes.dividerProductDetails} />
              <Divider className={classes.dividerProductDetails} />
              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.textProductDetailsSubtitle}
              >
                Dimensions
              </Typography>
              <Typography
                color="textSecondary"
                variant="h5"
                className={classes.textProductDetailsBody}
              >
                Length: {data?.productDetail?.length?.value}{" "}
                {data?.productDetail?.length?.unit}
              </Typography>
              <Typography
                color="textSecondary"
                variant="h5"
                className={classes.textProductDetailsBody}
              >
                Width: {data?.productDetail?.width?.value}{" "}
                {data?.productDetail?.width?.unit}
              </Typography>
              <Typography
                color="textSecondary"
                variant="h5"
                className={classes.textProductDetailsBody}
              >
                Height: {data?.productDetail?.height?.value}{" "}
                {data?.productDetail?.height?.unit}
              </Typography>
              <Divider className={classes.dividerProductDetails} />
              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.textProductDetailsSubtitle}
              >
                Materials
              </Typography>
              {data?.productDetail?.materials.map((item, idx) => {
                return (
                  <Grid container key={idx} style={{ marginBottom: "16px" }}>
                    <Grid item xs={8} md={10} style={{ paddingRight: "16px" }}>
                      <Typography
                        className={classes.textProductDetailsMaterialName}
                        color="textSecondary"
                        variant="h5"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        className={classes.textProductDetailsMaterialDetails}
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <ResizedImage
                        item={item?.images[0]?.img}
                        alternateText="Material"
                        multiples={[0.2, 0.06, 0.2, 0.06]}
                        fit={ImageFit.CONTAIN}
                        bg={hexToRGB(colors.white1)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              <Divider className={classes.dividerProductDetails} />
              <Divider className={classes.dividerProductDetails} />

              <Grid container>
                <Grid
                  item
                  xs={6}
                  md={6}
                  className={classes.containerCenterJustified}
                >
                  <IconButton
                    className={classes.iconBtnProductDetails}
                    onClick={() => {
                      if (isLoggedIn()) {
                        if (data?.productDetail?.unitPrice > 1) {
                          setCartItemAddEvent({
                            productId: parseInt(data?.productDetail?.id),
                            qty: 1,
                          });
                        } else setCartAddFailForInvalidPrice(true);
                      } else setShowLoginRedirection(true);
                    }}
                  >
                    <CartIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  className={classes.containerCenterJustified}
                >
                  <IconButton
                    className={classes.iconBtnProductDetails}
                    onClick={() => {
                      if (isLoggedIn()) {
                        setWishlistItemAddEvent(
                          parseInt(data?.productDetail?.id)
                        );
                      } else setShowLoginRedirection(true);
                    }}
                  >
                    <WishlistIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider className={classes.dividerProductDetails} />
              <Divider className={classes.dividerProductDetails} />
              {data?.productDetail?.designedBy?.customerProfile?.name ? (
                <>
                  <Typography
                    color="primary"
                    variant="h5"
                    style={{ marginBottom: "8px" }}
                  >
                    Designed By:{" "}
                  </Typography>
                  {data?.productDetail?.designedBy?.customerProfile?.photo ? (
                    <RouterLink to={`/designer/${data?.productDetail?.designedBy?.seoName}`}>
                      <ResizedImage
                        customStyle={{
                          borderRadius: "50%",
                          marginBottom: "8px",
                        }}
                        item={
                          data?.productDetail?.designedBy?.customerProfile?.photo
                        }
                        alternateText={
                          data?.productDetail?.designedBy?.customerProfile?.name
                        }
                        multiples={[0.2, 0.05, 0.0875, 0.0875]}
                        fit={ImageFit.COVER}
                        bg={hexToRGB(colors.white1)}
                      />
                    </RouterLink>
                  ) : (
                    <></>
                  )}

                  <Typography
                    color="primary"
                    variant="h5"
                    style={{ marginBottom: "8px" }}
                  >
                    {data?.productDetail?.designedBy?.customerProfile?.name}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Grid>
            {data?.productDetail?.reviews?.length === 0 ? (
              <></>
            ) : (
              <Grid
                item
                xs={12}
                md={12}
                container
                justifyContent="flex-start"
                style={{ marginTop: "16px" }}
              >
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="h3"
                    className={classes.productReviewMisc}
                  >
                    Reviews
                  </Typography>
                </Grid>
                <Grid item container xs={12} md={12}>
                  {data?.productDetail?.reviews?.map((item, idx) => {
                    return (
                      <Grid
                        item
                        key={idx}
                        xs={12}
                        md={12}
                        className={classes.productReviewMisc}
                      >
                        <ReviewCard
                          userName={item?.user?.name}
                          rating={item?.rating}
                          review={item?.review}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Typography
                color="textSecondary"
                variant="h3"
                className={classes.productReviewMisc}
              >
                Review this Product
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                className={classes.productReviewMisc}
              >
                Share your thoughts with other customers
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                component="legend"
                variant="h5"
                color="textSecondary"
                className={classes.productReviewMisc}
              >
                Add Rating
              </Typography>
              <Rating
                name="product-rating"
                value={productRating}
                className={classes.productReviewMisc}
                onChange={(event, newValue) => {
                  setProductRating(newValue);
                }}
              />
            </Grid>
            <CustomFormLargeWidth>
              <TextField
                variant="filled"
                type="text"
                InputLabelProps={{ color: "primary" }}
                id="review"
                multiline
                minRows={4}
                label="Share your thoughts here ..."
                onChange={(e) => setProductReview(e.target.value)}
                className={classes.productReviewMisc}
              />
            </CustomFormLargeWidth>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "8px 0px 0px 24px" }}
                onClick={() => {
                  if (isLoggedIn()) {
                    addReview();
                  } else setShowLoginRedirection(true);
                }}
              >
                Post
              </Button>
            </Grid>
            {!similarProductsData ||
              similarProductsData?.filterProducts?.products?.length === 0 ? (
              <></>
            ) : (
              <Grid
                item
                xs={12}
                md={12}
                container
                justifyContent="flex-start"
                style={{ marginTop: "16px" }}
              >
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="h3"
                    style={{ padding: "16px 0px 0px 24px" }}
                  >
                    Similar Products
                  </Typography>
                </Grid>
                <Grid item container xs={12} md={12}>
                  {similarProductsData?.filterProducts?.products?.map(
                    (item, idx) => {
                      return (
                        <Grid
                          item
                          key={idx}
                          xs={12}
                          md={4}
                          style={{ padding: "16px 24px" }}
                        >
                          <RouterLink to={`/product/${item?.seoName}`}>
                            <ItemCard
                              imageLink={item?.images[0]?.img}
                              name={item?.name}
                              price={item?.unitPrice}
                            />
                          </RouterLink>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </CommonLayout>
      {cartAddSuccess !== 0 ? (
        <CustomSnackbar
          open={cartSuccessSnackOpen}
          setOpen={setCartSuccessSnackOpen}
          severity="success"
          message="Item added to cart!"
        />
      ) : (
        <></>
      )}
      {cartAddFailForInvalidPrice ? (
        <CustomSnackbar
          open={cartAddFailForInvalidPrice}
          setOpen={setCartAddFailForInvalidPrice}
          severity="error"
          message="Can't add to cart, contact for price"
        />
      ) : (
        <></>
      )}
      {wishlistSuccessSnackOpen ? (
        <CustomSnackbar
          open={wishlistSuccessSnackOpen}
          setOpen={setWishlistSuccessSnackOpen}
          severity="success"
          message="Item added to wishlist!"
        />
      ) : (
        <></>
      )}
      {reviewSuccessSnackOpen ? (
        <CustomSnackbar
          open={reviewSuccessSnackOpen}
          setOpen={setReviewSuccessSnackOpen}
          severity="success"
          message="Your review posted successfully!"
        />
      ) : (
        <></>
      )}
      {showLoginRedirection ? (
        <CustomDialog
          open={showLoginRedirection}
          handlePositiveButtonAction={() =>
            history.push(`/login?redirectUrl=/product/${seoName}`)
          }
          positiveButtonText="Sign In"
          handleNegativeButtonAction={() => setShowLoginRedirection(false)}
          negativeButtonText="Cancel"
        >
          <Typography
            variant="h4"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            Please sign in to continue
          </Typography>
        </CustomDialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductDetailsView;
