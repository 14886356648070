import { logEvent, setUserProperties } from "firebase/analytics";
import { analytics } from "../setupFirebase";

export function setUserProps (userName) {
    //console.log("setUserProps", userName);
    setUserProperties(analytics, {
        user_name: userName
    });
}
export function logPageView (url) {
    logEvent(analytics, 'page_view', {
        page_location: url
    });
}

export function loginPageView (customerName, success) {
    //console.log("logging login page view", customerName, success);
    logEvent(analytics, 'login', {
        customer_name: customerName,
        success: success
    });
}

export function signupPage (customerName, username) {
    logEvent(analytics, 'sign_up', {
        customer_name: customerName,
        user_name: username
    });
}

export function addToCartPage (customerName, action, productId, qty) {
    logEvent(analytics, 'add_to_cart', {
        product_id: productId,
        add_to_cart_action: action,
        customer_name: customerName,
        quantity: qty
    });
}

export function addToWishlistPage (userName, action, productId) {
    logEvent(analytics, 'add_to_wishlist', {
        customer_name: userName,
        product_id: productId,
        add_to_wishlist_action: action
    });
}

export function beginCheckoutPage (customerName) {
    logEvent(analytics, 'begin_checkout', {
        customer_name: customerName
    });
}

export function viewItemPage (productId, productSeoName) {
    logEvent(analytics, 'view_item', {
        product_seo_name: productSeoName,
        product_id: productId
    });
}

export function viewItemListPage () {
    logEvent(analytics, 'view_item_list', {});
}

export function purchasePage (orderId, customerName) {
    logEvent(analytics, 'purchase', {
        order_id: orderId,
        customer_name: customerName
    });
}
